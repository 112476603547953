import React, {useEffect, useState} from 'react';
import Request from "../../Network/Request";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import SelectedProductCell from "./Products/SelectedProductCell";
import {ButtonBase, Popover} from "@mui/material";
import Payments from "./Payments";
import ImageView from "../ImageView";

function CheckoutPayment(props) {
    const [checkoutplaces, setCheckoutPlaces] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(function () {
        /*var checkoutRequest = new Request({
            name: "Workplaces",
            path: "/api/checkout/findworkplaces",
            params : {
                checkoutID : searchParams.get("checkoutID")
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {

                setCheckoutPlaces(response.checkoutplaces)

                console.log(response)
            }
        }.bind(this));
*/
    }, []);


    function selectPayment(payment) {
        var selectedPayment = props.selectPayment;
        selectedPayment(payment)
    }

    return <Paper elevation={2}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" padding={'10px'} bgcolor={'#ffffff'}>
            <Grid item md={4}>
                <ImageView height={'50px'} image={props.paymentType.image}></ImageView>
            </Grid>
            <Grid md={8} container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="stretch" p
                  paddingLeft={'10px'}>
                <Grid container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      md={12}>
                    <Typography align="left" variant="body2" component="div">
                        { props.paymentType.name }
                    </Typography>
                </Grid>
                <Grid container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch"
                    sm={2}md={12}>

                </Grid>
            </Grid>
        </Grid>
    </Paper>
}

export default CheckoutPayment;

/*
*  <Grid item>
                        <Typography align="left" variant="body2" component="div">
                            { props.paymentType.details }
                        </Typography>
                    </Grid>
* */
