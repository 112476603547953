import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, ButtonBase, LinearProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import ImageView from "../../ImageView";
import SectionView from "../../Layout/SectionView";
import VStack from "../../Layout/VStack";
import HStack from "../../Layout/HStack";
import {usePersistedState} from "../../Imports";
import ReactGA from "react-ga4";
import Request from "../../../Network/Request";
import WorkplaceCell from "../../Workplaces/WorkplaceCell";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CheckoutProductCell from "../../Checkout/Products/CheckoutProductCell";
import TimeView from "../../TimeView";
import VariationUserCell from "../../Checkout/Variation/VariationUserCell";
import dayjs from "dayjs";
import {LocalizationProvider, MobileDateTimePicker, StaticDateTimePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import CenterStack from "../../Layout/CenterStack";
import AppBar from "@mui/material/AppBar";
import DateView from "../ReservationsView/DateView";
import ErrorView from "../CustomViews/ErrorView";
import LocationView from "../LocationView/LocationView";
import TranslationView from "../CustomViews/TranslationView";
import PaymentCell from "../Cells/PaymentCell";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function CheckoutView(props) {
    //const navigate = useNavigate();
    
    let { linkID } = useParams();

    const [selectedCategory, setSelectedCategory] = usePersistedState("selectedCategory", {});
    const [selectedWorkplace, setSelectedWorkplace] = usePersistedState("selectedWorkplace", {});
    const [selectedProduct, setSelectedProduct] = usePersistedState("selectedProduct", {});
    const [selectedUserVariation, setSelectedUserVariation] = usePersistedState("selectedUserVariation", {});
    const [selectedLocation, setSelectedLocation] = usePersistedState("selectedLocation", null);

    const [open, setOpen] = useState(false)
    const [error, setError] = useState(null)

    //List Worker Products
    const [listingWorkerProducts, setListingWorkerProducts] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const [selectedWorkerProducts, setSelectedWorkerProducts] = useState([]);

    const [checkout, setCheckout] = useState(null);
    const [payments, setPayments] = useState([]);
    const [changedDate, setChangedDate] = useState(null);

    const [showBar, setShowBar] = useState(false);

    useEffect(function () {
        if (loaded == true) {
            return
        }

        ReactGA.event({
            category: 'User',
            action: 'Checkout'
        });

        loadPaymentTypes()

        var params = {
            categoryID : selectedCategory.categoryID,
            workplaceID : selectedWorkplace.workplaceID,
            productID : selectedProduct.productID,
            variationUserID: selectedUserVariation.variationUserID
        }

        if (linkID) {
            params = {
                linkID : linkID
            }
        }

        if (selectedLocation) {
            var latitude = selectedLocation.latitude
            var longitude = selectedLocation.longitude

            if (latitude && longitude) {
                params.startCoordinates = [latitude, longitude]
                console.log("📍 Start Coordinates: " + params.startCoordinates)
            }
        }

        var checkoutRequest = new Request({
            name: "Checkout Products Start",
            path: "/api/checkout/start",
            params : params
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                var checkout = response.checkout;
                var variationUser = checkout.variationUser
                setCheckout(checkout)
                setSelectedCategory(checkout.category)
                setSelectedWorkplace(checkout.worker.workplace)

                if (!variationUser) {
                    window.location = '/variations'
                }
            }
            else
            {
                setTimeout(function () {
                    //navigate(-1)
                }, 3000)
            }

            setLoaded(true)
            setError(error)
        }.bind(this));

    }, [loaded]);


     useEffect(function () {
        if (!checkout) {
            return
        }

        var checkoutRequest = new Request({
            name: "Checkout Product List",
            path: "/api/checkout/productslist",
            params : {
                checkoutID : checkout.checkoutID
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                var workerProducts = response.workerProducts.sort(function (wp1, wp2) {
                    var product1 = wp1.product
                    var product2 = wp2.product


                    if ( product1.priority < product2.priority ){
                        return -1;
                    }
                    if ( product1.priority > product2.priority ){
                        return 1;
                    }

                    return 0;

                    return wp1.product.title < wp2.product.title ?? false
                })

                setListingWorkerProducts(workerProducts)
            }
        }.bind(this));

    }, [checkout]);

    useEffect(function () {
        setShowBar(selectedWorkerProducts.length > 0)

        if (selectedWorkerProducts.length == 0) {
            return;
        }

        var checkoutRequest = new Request({
            name: "Checkout Set Products",
            path: "/api/checkout/products",
            params : {
                checkoutID : checkout.checkoutID,
                productIDs : selectedWorkerProducts.map(function (workerProduct) {
                    return workerProduct.product.productID;
                })
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                setLoaded(true)
                setCheckout(response.checkout)
            }
        }.bind(this));

    }, [selectedWorkerProducts])

    function addProduct(product) {
        setSelectedWorkerProducts([product])

        return
        var set = new Set(selectedWorkerProducts)
        set.add(product)

        setSelectedWorkerProducts(Array.from(set))
    }

    function removeProduct(product) {
        var selected = checkout.workerProducts.filter(function (prod) {
            return prod.workerProductID != product.workerProductID
        })

        setSelectedWorkerProducts(selected)
    }

    function contains(product) {
        var selected = checkout.workerProducts.filter(function (prod) {
            return prod.workerProductID == product.workerProductID
        });

        return selected.length != 0
    }

    function handleClose() {

    }

    function setDate(date) {
        var params = {
            checkoutID : checkout.checkoutID,
            date : date
        }

        var checkoutRequest = new Request({
            name: "Checkout Set Date",
            path: "/api/checkout/date",
            params : params
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                setCheckout(response.checkout)
                setLoaded(true)
            }
            else
            {
                console.log(error)
                setTimeout(function () {
                    //navigate(-1)
                }, 3000)
            }
        }.bind(this));
    }

    function loadPaymentTypes() {
        var paymentRequest = new Request({
            name: "List Payments",
            path: "/api/shop/payment/list"
        });

        paymentRequest.start(function (error, response) {
            if (!error) {
                setPayments(response.paymentTypes)
                console.log(response)
            }
        }.bind(this));
    }

    function selectPaymentType(paymentType) {
        var paymentRequest = new Request({
            name: "Checkout Set Payment",
            path: "/api/checkout/payment",
            params: {
                paymentTypeID: paymentType.paymentTypeID,
                checkoutID: checkout.checkoutID
            }
        });

        paymentRequest.start(function (error, response) {
            if (!error) {
                setCheckout(response.checkout)
                //props.checkoutChanged(response.checkout)
            }
        }.bind(this));
    }

    function workplaceCell() {
        if (checkout && checkout.worker && checkout.worker.workplace) {
            return <WorkplaceCell workplace={ checkout.worker.workplace }></WorkplaceCell>
        }

        return <></>
    }

    function acceptDate(date) {
        setOpen(false)
        var date = new Date(date)
        if (changedDate) {
            setDate(changedDate)
            setChangedDate(null)
        }
    }

    function onChange(date) {
        var date = new Date(date)
        setChangedDate(date)
    }

    if (!loaded) {
        return <LinearProgress />
    }

    if (!checkout && loaded) {
        return <VStack>
            <></>
            <ErrorView error={error}></ErrorView>
        </VStack>
    }

    return<>
        <Modal open={open} onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style} >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDateTimePicker defaultValue={dayjs(checkout.startDate)}  ampm={false} onAccept={acceptDate} onChange={onChange} onClose={function () {
                        setOpen(false)
                    }}/>
                </LocalizationProvider>
            </Box>
        </Modal>
        <VStack>
            <LocationView></LocationView>
            <Typography align={'left'} variant="h5" component="div" gutterBottom paddingLeft={'10px'}>
                <TranslationView>
                    Location
                </TranslationView>
            </Typography>
            <SectionView>
                {
                    workplaceCell()
                }
            </SectionView>
            <Typography align={'left'} variant="h5" component="div" gutterBottom paddingLeft={'10px'}>
                <TranslationView>
                    { selectedCategory.variationName }
                </TranslationView>
            </Typography>
            <SectionView>
                <HStack padding={'10px'}>
                    <VariationUserCell md={10} sm={10} xs={9} variationUser={checkout.variationUser}></VariationUserCell>
                    <Button md={1} xs={3 } variant={'contained'} onClick={function () {
                        window.location = '/variations'
                    }}>Change</Button>
                </HStack>
            </SectionView>
            <Typography align={'left'} variant="h5" component="div" gutterBottom paddingLeft={'10px'}>
                <TranslationView>
                    Products
                </TranslationView>
            </Typography>
            <VStack>
                {
                    listingWorkerProducts.map(function (workerProduct) {
                        return <CheckoutProductCell variant={'checkout'} selected={contains(workerProduct)} key={ workerProduct.workerProductID } contains={contains(workerProduct)} selectWorkerProduct={function (workerProduct) {
                            addProduct(workerProduct);
                        }} removeWorkerProduct={function (workerProduct) {
                            removeProduct(workerProduct)
                        }} workerProduct={ workerProduct }></CheckoutProductCell>
                    })
                }
                <Typography align={'right'}  variant="h5" >Time: {checkout.duration} Minutes</Typography>
                <Typography color={'black'} align={'right'} variant="h5" component="div" gutterBottom>
                    Total : { checkout.price } RON
                </Typography>

            </VStack>
            <Typography align={'left'} variant="h5" component="div" gutterBottom paddingLeft={'10px'}>
                <TranslationView>
                    Time
                </TranslationView>
            </Typography>
            <SectionView paddingBottom={'50px'}>
                <HStack>
                    <VStack md={10}>
                        <HStack paddingTop={'20px'}>
                            <Typography variant={'body1'} md={2}>Date: </Typography>
                            <DateView date={checkout.startDate}></DateView>
                        </HStack>
                        <HStack padding={'0px'}>
                            <Typography variant={'body1'} md={2}>From: </Typography>
                            <TimeView startDate={checkout.startDate}></TimeView>
                            <Typography variant={'body1'} md={1}> to </Typography>
                            <TimeView startDate={checkout.endDate} ></TimeView>
                        </HStack>
                    </VStack>
                    <Button variant={'contained'} onClick={function (event) {
                        setOpen(true)
                    }}>
                        Change
                    </Button>
                </HStack>
            </SectionView>
            <Typography align={'left'} variant="h5" component="div" gutterBottom paddingLeft={'10px'}>
                <TranslationView>
                    Payment
                </TranslationView>
            </Typography>
            <VStack spacing={'0px'}>
                {
                    payments.map(function (payment) {
                        return <SectionView key={payment.paymentTypeID} selected={checkout.paymentType.paymentTypeID == payment.paymentTypeID}>
                            <PaymentCell payment={payment} onSelect={selectPaymentType}></PaymentCell>
                        </SectionView>
                    })
                }
            </VStack>
            <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
                <CenterStack>
                    <VStack padding={'10px'}>
                        <Button fullWidth style={{height : '50px'}} variant={'contained'} onClick={function () {
                            window.location = "/ordersummary/" + checkout.checkoutID
                        }}>Checkout</Button>
                    </VStack>
                </CenterStack>
            </AppBar>
        </VStack>
    </>
}

export default CheckoutView;

/*
*
                    <MobileDateTimePicker open={open} defaultValue={dayjs(checkout.startDate)} ampm={false} onAccept={acceptDate} onChange={onChange} onClose={function () {
                        setOpen(false)
                    }}/>
* */