import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import {ButtonBase, CardMedia, Rating} from "@mui/material";
import Box from "@mui/material/Box";
import ImageView from "../../ImageView";
import Typography from "@mui/material/Typography";
import {useParams, useSearchParams} from "react-router-dom";
import Request from "../../../Network/Request";
import VariationCell from "./VariationCell";
import VariationUserCell from "./VariationUserCell";
import VariationAdd from "./VariationAdd";
import {usePersistedState} from "../../Imports";

function VariationList(props) {
    let { categoryID } = useParams();
    const [variations, setVariations] = useState([]);
    const [createVariation, setCreateVariation] = useState(false);
    const [selectedVariation, setSelectedVariation] = useState(false);
    const [selectedCategory, setSelectedCategory] = usePersistedState("selectedCategory", {});

    const [userVariations, setUserVariations] = useState([]);
    const [loadUserVariations, setLoadUserVariations] = useState(true);

    function getCategoryID() {
        if (categoryID) {
            return categoryID
        }

        if (props.categoryID)
        {
            return props.categoryID
        }

        if (selectedCategory)
        {
            return selectedCategory.categoryID
        }

        return ""
    }

    useEffect(function () {
        var categoryList = new Request({
            name: "Variation Category List",
            path: "/api/variations/category",
            params : {
                categoryID : getCategoryID()
            }
        });

        categoryList.start(function (error, response) {
            if (!error) {
                setVariations(response.variations)
            }
        }.bind(this));
    }, []);

    useEffect(function () {
        if (!loadUserVariations) {
            return
        }

        var userVariationsList = new Request({
            name: "Variations User List",
            path: "/api/variations/user",
            params : {
                categoryID : getCategoryID()
            }
        });

        userVariationsList.start(function (error, response) {
            if (!error) {
                setUserVariations(response.variationsUser)
                setLoadUserVariations(false)
            }
        }.bind(this));
    }, [loadUserVariations]);

    useEffect(function () {
        if (selectedVariation) {
            setCreateVariation(true)
        }
    }, [selectedVariation]);

    function startCheckout() {
        var checkoutRequest = new Request({
            name: "Checkout",
            path: "/api/checkout/start",
            params : {
                categoryID : getCategoryID()
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                window.location = '/workplaces/' + response.checkout.checkoutID;
            }
        }.bind(this));
    }
    
    function deleteVariationUser(variationUser) {
        var userVariationsDelete = new Request({
            name: "Variations User Delete",
            path: "/api/variations/delete",
            params : {
                variationUserID : variationUser.variationUserID
            }
        });

        userVariationsDelete.start(function (error, response) {
            if (!error) {
                setLoadUserVariations(true)
            }
        }.bind(this));
    }

    function selectedVariationUser(variationUser) {
        startCheckout()
    }

    function addUserVariationUI() {
        return  <VariationAdd variation={selectedVariation} onAddUserVariation={function (userVariation){
            setCreateVariation(false)
            setLoadUserVariations(true)
            setSelectedVariation(null)
        }} onBack={function (event){
            setCreateVariation(false)
            setSelectedVariation(null)
        }}></VariationAdd>
    }

    function userVariationsUI() {
        return <>
            {
                userVariations.map(function (userVariation) {
                    return <VariationUserCell variationUser={userVariation} selected={selectedVariationUser} onDelete={deleteVariationUser}></VariationUserCell>
                })
            }

            <Button onClick={function () {
                setCreateVariation(true)
            }}>Adauga una noua</Button>
        </>
    }

    function variationsSiumpleUI() {
        return variations.map(function (variation) {
            return <VariationCell variation={variation} selected={function (variation) {
                setSelectedVariation(variation)
                setCreateVariation(true)
            }}></VariationCell>
        })
    }

    function variationsUI() {
        if (createVariation) {
            if (!selectedVariation) {
                return variationsSiumpleUI()
            }

           return addUserVariationUI()
        }

        if (userVariations.length == 0 || createVariation) {
           return variationsSiumpleUI()
        }

        return userVariationsUI()
    }

    return <Paper tran elevation={1}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item md={12}>
                    {
                        variationsUI()
                    }
                </Grid>
                <Grid item md={12}>

                </Grid>
                <Grid item>

                </Grid>
                <Grid item>

                </Grid>
            </Grid>
        </Paper>
}

export default VariationList;
