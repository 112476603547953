import React, {useEffect, useState} from 'react';
import Request from "../../../Network/Request";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {ButtonBase} from "@mui/material";
import ImageView from "../../ImageView";

function Variation(props) {
    const [checkoutplaces, setCheckoutPlaces] = useState([]);
    let variation = props.variation

    useEffect(function () {
        /*var checkoutRequest = new Request({
            name: "Workplaces",
            path: "/api/checkout/findworkplaces",
            params : {
                checkoutID : searchParams.get("checkoutID")
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {

                setCheckoutPlaces(response.checkoutplaces)

                console.log(response)
            }
        }.bind(this));
*/
    }, []);

    if (!variation) {
        return <></>
    }

    return <Paper elevation={5}>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch" padding={'10px'}>
            <Grid item md={2}>
                <ImageView image={variation.image}></ImageView>
            </Grid>
            <Grid container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  md={8}
                bgcolor={'#ffffff'}>
                <Grid container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch">
                    <Typography variant="h4" component="div" gutterBottom>
                        { variation.name }
                    </Typography>
                </Grid>
                <Grid container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch">
                    <Typography variant="h6" component="div" gutterBottom>
                        { variation.details }
                    </Typography>
                </Grid>
            </Grid>
            <Grid item md={2}>
                <ButtonBase onClick={props.onClick}>
                    <Typography variant="h4" component="div" gutterBottom>
                        Change
                    </Typography>
                </ButtonBase>
            </Grid>
        </Grid>
        </Paper>
}

export default Variation;
