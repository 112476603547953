import React, {useEffect, useState} from 'react';
import Request from "../../Network/Request";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useSearchParams } from "react-router-dom";
import Categories from "../Categories/Categories";
import Grid from "@mui/material/Grid";
import Variation from "../Checkout/Variation/Variation";
import {usePersistedState} from "../Imports";
import ImageView from "../ImageView";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HStack from "../Layout/HStack";
import SectionView from "../Layout/SectionView";
import VStack from "../Layout/VStack";
import Typography from "@mui/material/Typography";

function Profile(props) {
    const [loaded, setLoaded] = useState(false);
    const [session, setSession] = usePersistedState("session", {});
    const [user, setUser] = usePersistedState('user', null);
    const [profile, setProfile] = useState({});

    useEffect(function () {
        if (loaded){
            return
        }

        var userProfileRequest = new Request({
            name: "User Profile",
            path: "/api/user/profile"
        });

        userProfileRequest.start(function (error, response) {
            if (!error) {
                setProfile(response.profile)
                console.log(response)
            }
        }.bind(this));

    }, [loaded]);

    function logout() {
        var checkoutRequest = new Request({
            name: "Logout",
            path: "/authenticate/logout"
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                setSession(null)
                setUser(null)
                window.location = "/"
            }
        }.bind(this));
    }

    function dashboardButton() {
        if (session && user && user.userRoles.includes("admin")) {
            return <Button w={2} color='primary' onClick={function () {
                window.location = "/dashboard"
            }}><DashboardIcon/></Button>
        }

        return <></>
    }

    return  <SectionView>
            <HStack padding={'10px'}>
                <ImageView md={2} image={profile.avatar}></ImageView>
                <VStack md={10} padding={'10px'}>
                    <Button fullWidth variant='outlined' href={'/reservations/history'}>Reservation History</Button>
                    <Button fullWidth variant='outlined' href={'/contact'}>Contact us</Button>
                    <Button variant={'outlined'} onClick={function () {
                        logout()
                    }}>Logout</Button>
                </VStack>
            </HStack>

        </SectionView>
}

export default Profile;
