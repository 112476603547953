import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {ButtonBase, CardMedia} from "@mui/material";
import Ratio from "react-ratio";

function getName(width) {
    if (width < 500) {
        return "portrait"
    }
    return "cover"
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

function ImageView(props) {
    const { height, width } = useWindowDimensions();

    function getImage() {
        console.log(width)

        return props.image.replace('cover',getName(width))
    }
    
    function images() {
        var images = []

        images.push(props.image)

        return images.map(function (image) {
            return {
                original : image,
                thumbnail: image
            }
        })
    }

    return <Box padding={'3px'}>
        <CardMedia sx={{ borderRadius: 2 }} height={props.height} component="img" image={props.image}  alt={props.image}/>
    </Box>
}

export default ImageView;
