import React, {useEffect, useState} from 'react';
import Request from "../../Network/Request";
import Button from '@mui/material/Button';
import {useParams, useSearchParams} from "react-router-dom";
import WorkplaceCell from "../Workplaces/WorkplaceCell";
import Typography from "@mui/material/Typography";
import VariationUserCell from "../Checkout/Variation/VariationUserCell";
import {usePersistedState} from "../Imports";
import VStack from "../Layout/VStack";
import HStack from "../Layout/HStack";
import TimeView from "../TimeView";
import DateView from "../Views/ReservationsView/DateView";

function ReservationCell(props) {
    var { reservationID } = useParams();

    const [loaded, setLoaded] = useState(true);
    const [reservations, setReservations] = useState([]);
    const [translations, setTranslations] = usePersistedState('translations', {});

    useEffect(function () {

        return;
        if (loaded){
            return
        }

        var reservationList = new Request({
            name: "Reservation List",
            path: "/api/reservation/list",
            params : {
                reservationID : reservationID
            }
        });

        reservationList.start(function (error, response) {
            if (!error) {
                console.log(response)
                //setReservation(response.reservation)
                setLoaded(true)
            }
        }.bind(this));
    }, [loaded]);

    var reservation = props.reservation
    var workplace = props.reservation.worker.workplace
    var variationUser = props.reservation.variationUser
    var workerProducts = props.reservation.workerProducts

    return <VStack padding={'10px'}>
        <Typography align={'left'}>{translations[reservation.status] ?? reservation.status}</Typography>
        <WorkplaceCell workplace={workplace} ></WorkplaceCell>
        <VariationUserCell variationUser={variationUser} ></VariationUserCell>
        <HStack>
            <DateView date={reservation.startDate}></DateView>
            <TimeView startDate={reservation.startDate}></TimeView>
            <TimeView startDate={reservation.endDate}></TimeView>
        </HStack>
        {
            workerProducts.map(function (workerProduct) {
                return <Typography key={workerProduct.workerProductID}>{ workerProduct.product.title}</Typography>
            })
        }
        <Button variant={'contained'} fullWidth onClick={function () {
            window.location = "/reservation/" + props.reservation.reservationID
        }}>Details</Button>
    </VStack>
}

export default ReservationCell;
