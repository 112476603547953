import { useParams } from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, LinearProgress, TextField} from "@mui/material";
import SectionView from "../../Layout/SectionView";
import VStack from "../../Layout/VStack";
import Request from "../../../Network/Request";
import {usePersistedState} from "../../Imports";
import VariationCell from "../../Checkout/Variation/VariationCell";
import VariationUserCell from "../../Checkout/Variation/VariationUserCell";

function VariationsView(props) {
    let { linkID } = useParams();

    const [selectedCategory, setSelectedCategory] = usePersistedState("selectedCategory", {});
    const [selectedUserVariation, setSelectedUserVariation] = usePersistedState("selectedUserVariation", {});

    const [loadUserVariations, setLoadUserVariations] = useState(true);
    const [userVariations, setUserVariations] = useState([]);

    const [loadVariations, setLoadVariations] = useState(false);
    const [selectedVariation, setSelectedVariation] = usePersistedState("selectedVariation", {});

    const [viewState, setViewState] = useState(-1); // 0 - Variation List , 1 - Add Variation details, 2 - View user variations

    const [plateNR, setPlateNR] = useState(null)

    function categoryID() {
        return selectedCategory.categoryID
    }

    useEffect(function () {
        if (!loadUserVariations) {
            return
        }

        var userVariationsList = new Request({
            name: "Variations User List",
            path: "/api/variations/user",
            params : {
                categoryID : selectedCategory.categoryID
            }
        });

        userVariationsList.start(function (error, response) {
            if (!error) {
                var userVariations = response.variationsUser ?? []
                setUserVariations(userVariations)
                setLoadUserVariations(false)
                if (userVariations.length == 0) {
                    window.location = '/variation/new'
                }
                else
                {
                    setViewState(2)
                }
            }
        }.bind(this));
    }, [loadUserVariations]);

    function deleteUserVariation(userVariation) {
        var categoryList = new Request({
            name: "Variation Delete",
            path: "/api/variations/delete",
            params : {
                variationUserID : userVariation.variationUserID
            }
        });

        categoryList.start(function (error, response) {
            if (!error) {
                setLoadUserVariations(true)
            }
        }.bind(this));
    }

    function addVariation() {
        var variationAdd = new Request({
            name: "Variation New",
            path: "/api/variations/new",
            params : {
                title : plateNR,
                categoryID : categoryID(),
                variationID : selectedVariation.variationID
            }
        });

        variationAdd.start(function (error, response) {
            if (!error) {
                setLoadUserVariations(true)
                setSelectedVariation(null)
                setViewState(2)
            }
        }.bind(this));
    }

    function selectVariation(variation) {
        setSelectedVariation(variation)
        setViewState(1)
    }

    function next() {
        var location = '/checkout'
        if (linkID) {
            location = location + "/" + linkID
        }

        window.location = location
    }

    function handleTextField(event) {
        setPlateNR(event.target.value)
    }

    function viewStateView() {
        if (!loadUserVariations || userVariations.length != 0) {
            return <VStack padding={'10px'}>
                <TextField label={'Search'} fullWidth></TextField>
                {
                    userVariations.map(function (userVariation) {
                        return <SectionView key={userVariation.variationUserID}>
                            <VariationUserCell variationUser={userVariation} onSelect={function (userVariation) {
                                setSelectedUserVariation(userVariation)
                                next()
                            }} onDelete={function (userVariation) {
                                deleteUserVariation(userVariation)
                            }}>
                            </VariationUserCell>
                        </SectionView>
                    })
                }
                <Button fullWidth variant={'contained'} onClick={function (params) {
                    setSelectedVariation(null)
                    window.location = '/variation/new'
                }}>Add new</Button>
            </VStack>
        }
        else {
            return <LinearProgress />
        }
    }

    return <VStack paddingTop={'10px'}>
        {
            viewStateView()
        }
    </VStack>
}

export default VariationsView;