import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import WorkplaceList from "./Workplaces/WorkplaceList";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import VStack from "./Layout/VStack";

function TimeView(props) {

    function getDate() {
        return new Date(props.startDate)
    }

    function getDuration() {
        if (props.duration == null) {
            return <></>
        }

        return <Typography paddingLeft={'10px'} variant="subtitle1" component="div" color={'#443322'}>
            { props.duration } Minutes
        </Typography>
    }

    return <VStack>
        <Typography variant="h4" component="div" color={'#443322'}>
            { getDate().toLocaleTimeString([],{timeStyle: 'short'}) }
        </Typography>
    </VStack>
}

export default TimeView;
