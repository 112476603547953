import React from 'react';
import {Rating} from "@mui/material";
import Typography from "@mui/material/Typography";
import {usePersistedState} from "../../Imports";
import HStack from "../../Layout/HStack";
import TranslationView from "../../Views/CustomViews/TranslationView";

function RatingView(props) {
    const [translations] = usePersistedState('translations', {});

    function rating() {
        if (props.ratings.ratingCount) {
            return <HStack padding={'0px'}>
                <Typography variant={"h6"} align={'left'}> {
                    props.ratings.rating.toFixed(1)
                }</Typography>
                <Rating name="simple-controlled" precision={0.5} value={ props.ratings.rating } size="small" readOnly
                />
                <Typography>
                    {
                        "(" + props.ratings.ratingCount + " " + translations["ratings"] +")"
                    }
                </Typography>
            </HStack>
        }

        return  <Typography variant={"subtitle2"} align={'left'}>
            <TranslationView>
                No rating
            </TranslationView>
        </Typography>
    }

    return props.ratings ? rating() : "No ratings object";
}

export default RatingView;
