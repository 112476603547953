import React, {useEffect, useState} from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import Request from "../../../Network/Request";
import { SuperSEO } from "react-super-seo";
import {usePersistedState} from "../../Imports";

function SeoView(props) {
    let { keyword } = useParams();
    let { categoryName } = useParams();

    const [categories, setCategories] = useState([]);
    const [seo, setSeo] = usePersistedState("seo", {});

    const [workplaces, setWorkplaces] = useState([]);
    const [workplace, setWorkplace] = useState(null)

    useEffect(function () {
        var options = {
            name: "Seo List",
            path: "/api/seo/list"
        }

        if (keyword) {
            options.params = {
                keyword : keyword
            }
        }

        var seoListRequest = new Request(options);
        seoListRequest.start(function (error, response) {
            if (!error) {
                var seo = response.seo
                if (seo) {
                    setSeo(seo)
                }

                if (seo.product) {
                    window.location = '/product/' + seo.keyword
                    return;
                }

                if (seo.category && seo.category.isFinal)
                {
                    window.location = '/placelist/' + seo.keyword
                    return;
                }

                if (seo.category) {
                    window.location = '/category/' + seo.category
                    return;
                }

                if (seo.workplace) {
                    window.location = '/venue/' + seo.keyword
                    return;
                }
            }
            else
            {
                if (error.error_code == '867481') {
                    //window.location = '/notfound'
                }
            }
        }.bind(this));
    }, []);
    
    return <>
        <SuperSEO title={ seo.title } description={ seo.description } lang={ seo.locale }>
        </SuperSEO>
    </>
}

export default SeoView;
