import React, {useEffect, useState} from 'react';
import Request from "../../Network/Request";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useSearchParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import { sha256, sha224 } from 'js-sha256';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Alert, CircularProgress, MenuItem} from "@mui/material";
import ImageView from "../ImageView";
import {usePersistedState} from "../Imports";
import VStack from "../Layout/VStack";
import HStack from "../Layout/HStack";
import SectionView from "../Layout/SectionView";
import ScreenCenter from "../Layout/ScreenCenter";
import ErrorView from "../Views/CustomViews/ErrorView";

function LoginPhoneNumber(props) {
    const [categories, setCategories] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [loadedCountries, setLoadedCountries] = useState(false);
    const [credentials, setCredentials] = usePersistedState('phonecredentials',null);
    const [code, setCode] = useState(null);
    const [user, setUser] = usePersistedState('user', null);
    const [session, setSession] = usePersistedState('session', null);
    const [error, setError] = useState( null);

    useEffect(function () {
        if (loadedCountries) {
            return
        }
        var options = {
            name: "Countries",
            path: "/authenticate/countries"
        }

        var countriesRequest = new Request(options);
        countriesRequest.start(function (error, response) {
            if (!error) {
                var countries = response.countries;
                var defaultCountry = response.defaultCountry

                setCountries(countries)
                setSelectedCountry(defaultCountry)
                setLoadedCountries(true)
            }
        }.bind(this));


    }, [loadedCountries]);

    useEffect(function () {
        if (!credentials) {
            return
        }
    }, [credentials]);

    function sendSMS() {
        var number = "bc747e2841" + "d5a2583a28149438ffa6bfc" + "b91ed99c4d1a2a2608" + "0fa2fb2eaa00680d0f3e49" + "097e78f343805c28f7b3cb17579";
        var pHnumber = selectedCountry.dialCode + "" + phoneNumber;

        console.log(pHnumber)
        var total = pHnumber + number;
        console.log(total)

        var verif = String(sha256(total))

        var options = {
            name: "Login Phone",
            path: "/authenticate/loginnumber",
            params : {
                phoneNumber : pHnumber,
                verification : verif
            }
        }

        setCredentials(null)
        setError(null)

        var loginRequest = new Request(options);

        loginRequest.start(function (error, response) {
            if (!error) {
                setCredentials(response.credentials)
            }

            setError(error)
        }.bind(this));
    }

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    if (!loadedCountries || !countries.length || !selectedCountry) {
        return <CircularProgress color="secondary"/>
    }

    function handleChange(event) {
        var countryCode = event.target.value;

        countries.forEach(function (country) {
            if (country.code == countryCode) {
                setSelectedCountry(country)
            }
        })
    }

    function verifyCode() {
        var options = {
            name: "Login Code",
            path: "/authenticate/logincode",
            params : {
                credentialsID : credentials.credentialsID,
                validationCode : code
            }
        }

        var loginRequest = new Request(options);

        setError(null)
        loginRequest.start(function (error, response) {
            if (!error) {
                setUser(response.user)
                setSession(response.session)
                setCredentials(null)
                setTimeout(function () {
                    window.location = "/";
                },200)
            }

            setError(error)
        }.bind(this));
    }

    function actionButton() {
        if (!credentials) {
            return <Button fullWidth variant="contained" onClick={function () {
                sendSMS()
            }}>Send SMS</Button>
        }

        return <VStack>
            <Button fullWidth variant="contained" onClick={function () {
                verifyCode()
            }}>Verify Code</Button>
            <Button fullWidth variant="outlined" onClick={function () {
                sendSMS()
            }}>Resend SMS</Button>
        </VStack>
    }

    return <SectionView padding={'10px'}>
        <VStack padding={'10px'}>
            <Typography align={'left'} variant={'h5'} fontWeight={'bold'}>SMS Authentication</Typography>
            <Typography align={'left'} variant={'body1'}>Verify your account securely and conveniently by receiving a unique verification code to your registered mobile number. Enter the code in the designated field to authenticate your identity and gain seamless access to your account.</Typography>
            <HStack padding={'0px'}>
                <TextField
                    fullWidth id="outlined-select-currency" select
                    label="Select country"
                    value={selectedCountry.code}
                    SelectProps={{
                        native: true,
                    }} fullWidth onChange={handleChange}>
                    {
                        countries.map(function (country) {
                            return <option key={country.code} value={country.code}>
                                {country.name + " " + country.dialCode}
                            </option>
                        })
                    }
                </TextField>
                <img src={selectedCountry.imageURL}/>
            </HStack>
            <TextField fullWidth id="outlined-basic" label="Phone Number" variant="outlined" onChange={function (event) {
                setPhoneNumber(event.target.value)
            }}  />
            {
                credentials ? <VStack>
                    <Alert align={'left'} severity="success">Success! We've sent you an SMS with a verification code. Please enter the code below to proceed.</Alert>
                    <TextField paddingTop={'10px'} fullWidth id="outlined-basic-1" label="Verify Code" variant="outlined" onChange={function (event) {
                        setCode(event.target.value)
                    }}  />
                </VStack> : <></>
            }
            {
                actionButton()
            }
            <ErrorView error={error}></ErrorView>
        </VStack>
    </SectionView>
}

export default LoginPhoneNumber;
