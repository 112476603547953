import React, {useEffect, useState} from 'react';
import Request from "../../Network/Request";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import {useParams, useSearchParams} from "react-router-dom";
import Categories from "../Categories/Categories";
import Grid from "@mui/material/Grid";
import Variation from "../Checkout/Variation/Variation";
import WorkplaceCell from "../Workplaces/WorkplaceCell";
import CheckoutPayment from "../Checkout/CheckoutPayment";
import {ButtonBase} from "@mui/material";
import Box from "@mui/material/Box";
import VStack from "../Layout/VStack";
import HStack from "../Layout/HStack";
import Typography from "@mui/material/Typography";
import ImageView from "../ImageView";

function ActionCell(props) {
    const [loaded, setLoaded] = useState(true);
    const [reservation, setReservation] = useState({});
    const [actions, setActions] = useState([]);

    useEffect(function () {
        if (loaded){
            return
        }

        var checkoutRequest = new Request({
            name: "Reservation Details",
            path: "/api/reservation/details",
            params : {

            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                console.log(response)
                setReservation(response.reservation)
                setLoaded(true)
            }
        }.bind(this));
    }, [loaded]);

    return <VStack>
        <VStack sx={{ border: 1, borderColor: props.color }}>
            <HStack padding={'10px'}>
                <ImageView style={{height:'40px', width:'40px'}} md={1} xs={1} sm={1} image={props.action.image}></ImageView>
                <VStack padding={'10px'} md={9}>
                    <Typography align={'left'}>{ props.action.title }</Typography>
                    <Typography align={'left'}>{ props.action.details }</Typography>
                </VStack>
                <Button variant={'contained'} onClick={function () {
                    props.onActionSelected(props.action)
                }}>Select</Button>
            </HStack>
        </VStack>
    </VStack>
}

export default ActionCell;
