import React, {useEffect, useState} from 'react';
import Request from "../../Network/Request";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useSearchParams } from "react-router-dom";
import { sha256, sha224 } from 'js-sha256';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PhoneNumber from "./LoginPhoneNumber";
import LoginEmail from "./LoginEmail";
import Pocket from "pocketjs";

function Login(props) {

    const [categories, setCategories] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();

    function isLoggedIn() {
        var pocket = new Pocket();
        pocket.restore()

        var sessionPocket = pocket.collection('sessions');
        var currentSession = sessionPocket.findOne();

        if (currentSession) {
            return true
        }

        return false
    }

    useEffect(function () {
        if (isLoggedIn()) {
           // window.location ='/'
            return
        }

        console.log("Logged in")
    }, []);

    function sendSMS() {
        var number = "bc747e2841d5a2583a28149438ffa6bfcb91ed99c4d1a2a26080fa2fb2eaa00680d0f3e49097e78f343805c28f7b3cb17579";
        var pHnumber = "+40730215784";

        var total = pHnumber + number;
        console.log(total)

        var verif = String(sha256(total))

        var options = {
            name: "Login Phone",
            path: "/authenticate/loginnumber",
            params : {
                phoneNumber : pHnumber,
                verification : verif
            }
        }

        var loginRequest = new Request(options);

        loginRequest.start(function (error, response) {
            if (!error) {
                window.location = "logincode/" + response.credentials.credentialsID;
            }
        }.bind(this));
    }

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return <Grid container direction="row" justifyContent="center" alignItems="center" bgcolor={'#ffffff'}>
            <Grid item md={0} bgcolor={'#ffffff'}>
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="center" md={12} bgcolor={'#ffffff'} padding={'20px'}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Paper elevation={2} >
                    <Grid item md={12} paddingTop={'50px'}>
                        <img style={{ height: '50px' }} alt="complex" src="https://washplanfiles.ams3.cdn.digitaloceanspaces.com/assets/logo/LogoLiteApp_color.png" />
                    </Grid>
                    <Grid item md={12} paddingTop={'50px'}>
                        <Typography variant={'h6'}>Login with phone number</Typography>
                    </Grid>
                    <Grid item md={12}>
                        <PhoneNumber></PhoneNumber>
                    </Grid>
                        <Grid item md={12} paddingTop={'50px'}>
                        </Grid>
                    <Grid item md={12} paddingTop={'50px'}>
                        <Typography variant={'h6'}>Login with email</Typography>
                    </Grid>
                    <Grid item md={12}>
                        <LoginEmail></LoginEmail>
                    </Grid>
                        <Grid item md={12} paddingTop={'50px'}>
                        </Grid>
                    {/*
                    <Grid item md={12}>
                        <Typography variant={'h5'}>Social</Typography>
                    </Grid>
                    <Grid item md={4}></Grid>
                    <Grid item  md={4}>

                        <Button fullWidth variant="contained" onClick={function () {

                        }
                        }>Facebook</Button>

                    </Grid>
                    <Grid item md={4}></Grid>
                    <Grid item md={4}></Grid>
                    <Grid item md={4}>
                        {
/*
                            <Button fullWidth variant="contained" onClick={function () {

                            }
                            }>Apple</Button>

                    </Grid>
                    <Grid item md={4}></Grid>
                    */
                    }
                    </Paper>
                </Grid>
            </Grid>
            <Grid item md={0}></Grid>
        </Grid>
}

export default Login;
