import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import HStack from "../../Layout/HStack";
import VStack from "../../Layout/VStack";


var links = [
    "/",
    "/product/87ffefe450280127a322",
    "/workerProduct/workerProductID",
    "/workplace/workplaceID",
    "/workplacelist/listID",
    "/checkoutview/checkoutID",
    "/reservations",
    "/reservation/reservationID",
    "/category",
    "/login",
    "/phoneCode",
    "/email",
    "/emailCode",
    "/layoutTest"
]

function TestView(props) {
    let { linkID } = useParams();

    useEffect(function () {
        console.log("Nice" + linkID)
    }, []);

    function redirect() {
        window.location = "/checkout"
    }

    return <>
        {
            links.map(function (link) {
                return <>
                    <HStack>
                        <VStack md={6}>
                            <Typography>{link}</Typography>
                        </VStack>
                        <Button variant="contained" onClick={function () {
                            window.location = link
                        }}>Go</Button>
                    </HStack>
                </>
            })
        }
    </>
}

export default TestView;