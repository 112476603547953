import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import {ButtonBase} from "@mui/material";
import Typography from "@mui/material/Typography";
import ImageView from "../ImageView";
import { usePersistedState } from "../Imports";
import RatingView from "../Checkout/Ratings/RatingView";
import SectionView from "../Layout/SectionView";
import VStack from "../Layout/VStack";
import HStack from "../Layout/HStack";

function WorkplaceCell(props) {
    const [selectedWorkplace, setSelectedWorkplace] = usePersistedState("selectedWorkplace", {});

    useEffect(function () {
        return;
    }, []);

    var workplace = props.workplace

    function detailsButton() {
        if (props.showDetailsButton) {
            return <Button variant={'contained'} onClick={function () {
                setSelectedWorkplace(workplace)
                window.location = '/variations'
            }}>Select</Button>
        }

        return <></>
    }

    function address() {
        var city = props.workplace.city
        var county = props.workplace.county
        var street = props.workplace.street

        if (city == county) {
            return street + ", " + city
        }

        return street + ", " + city + ", " + county
    }

    function mapsLink() {
        return "http://www.google.com/maps/place/" + workplace.coordinates[0] + "," + workplace.coordinates[1]
    }

    function navigateLink() {
        return "https://www.waze.com/ul?ll=" + workplace.coordinates[0] + "%2C" + workplace.coordinates[1] + "&navigate=yes&zoom=17"
    }

    return <VStack paddingTop={'10px'}>
        <ImageView md={6} xs={4} sm={6} image={ workplace.images[0] }></ImageView>
        <VStack md={6} sm={6} xs={8} padding={'10px'} spacing={'0px'}>
            <Typography variant={"h5"} fontWeight={'bold'} align={"left"}>{props.workplace.name}</Typography>
            <RatingView ratings={ workplace.ratingTotal }></RatingView>
            <Typography variant={"subtitle1"} align={'left'}>{ address() }</Typography>
            <HStack paddingTop={'10px'}>
                {
                    detailsButton()
                }
            </HStack>
        </VStack>
    </VStack>
}

export default WorkplaceCell;
/*
<Button fullWidth variant={'outlined'} href={ mapsLink() } target="_blank" >See on map</Button>
<Button fullWidth variant={'outlined'} href={ navigateLink() } target="_blank">Navigate</Button>
 */