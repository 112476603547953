import { useParams } from "react-router-dom";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import SectionView from "../../Layout/SectionView";
import VStack from "../../Layout/VStack";
import WorkplaceCell from "../../Workplaces/WorkplaceCell";
import Request from "../../../Network/Request";
import {usePersistedState} from "../../Imports";
import SeobleView from "../SeoView/SeobleView";

function WorkplaceView(props) {
    let { workplaceID } = useParams();

    const [workplace, setWorkplace] = useState(null)
    const [seo, setSeo] = usePersistedState("seo", {});

    useEffect(function () {
        var loadPage = new Request({
            name: "Workplace Details Page",
            path: "/api/workplace/details",
            params: {
                workplaceID : workplaceID
            }
        });

        loadPage.start(function (error, response) {
            if (!error) {
                setWorkplace(response.workplace)
            }
        }.bind(this));

        console.log("Nice" + workplaceID)
    }, []);

    if (!workplace) {
        return <></>
    }

    return <>
        <VStack>
            <SeobleView></SeobleView>
            <SectionView>
                <VStack>
                    <WorkplaceCell workplace={ workplace }></WorkplaceCell>
                </VStack>
            </SectionView>
        </VStack>
    </>
}

export default WorkplaceView;
/*
<SectionView>
    <VStack>
        <Typography>Workplace Details</Typography>
    </VStack>
</SectionView>
<SectionView>
    <VStack>
        <Typography>Workplace Ratings</Typography>
    </VStack>
</SectionView>
<SectionView>
    <VStack>
        <Typography>Worker Products</Typography>
    </VStack>
</SectionView>*/