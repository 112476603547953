import React from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import {ButtonBase, CardMedia} from "@mui/material";
import Box from "@mui/material/Box";
import ImageView from "../../ImageView";
import Typography from "@mui/material/Typography";
import VStack from "../../Layout/VStack";
import HStack from "../../Layout/HStack";

function CheckoutProductCell(props) {

    function product() {
        return props.workerProduct.product;
    }

    function button() {
        if (props.selected) {
            return <Button fullWidth variant={'contained'} onClick={function () {
                props.removeWorkerProduct(props.workerProduct)
            }}>Remove</Button>
        }

        return <Button fullWidth variant={'contained'} onClick={function () {
            props.selectWorkerProduct(props.workerProduct)
        }} >Select</Button>
    }

    function bgColor() {
        if (props.selected) {
            return '#ffffff'
        }

        return '#ffffff'
    }

    function borderColor() {
        if (props.selected) {
            return 'primary.main'
        }

        return '#ffffff'
    }

    if (props.variant == "checkout") {
        return <Paper elevation={2} sx={{ border: 4, borderColor: borderColor()}} >
                <Grid container height={'100%'} bgcolor={bgColor()} direction="row" justifyContent="flex-start" alignItems="stretch" padding={'10px'}>
                    <Grid item md={4} xs={4} bgcolor={'#ffffff'}>
                        <ButtonBase href={'/product/' + product().productID}>
                            <ImageView image={product().images[0]}></ImageView>
                        </ButtonBase>
                    </Grid>
                    <Grid item md={8} xs={8} justifyContent="flex-start"  alignItems="flex-start" padding={'10px'} bgcolor={'#ffffff'}>
                        <Grid item xs={12} md={12}>
                            <Typography sx={{fontWeight: 'bold'}} variant="subtitle1" align="left" component="div" gutterBottom>
                                {
                                    product().title
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography variant="caption" align="left" component="div" >
                                {
                                    product().subtitle
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <Typography variant="caption" align="left" component="div" sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                            }}>
                                {
                                    product().details
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} paddingTop={'30px'}>
                            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                <Grid item >
                                    <Typography variant="h4" gutterBottom component="div">
                                        {
                                            props.workerProduct.price
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item paddingLeft={'10px'}>
                                    <Typography variant="h5" gutterBottom component="div">
                                        RON
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} paddingTop={'10px'}>
                            {
                                button()
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
    }

    if (props.variant == "ordersummary") {
        return <VStack>
            <HStack>
                <ButtonBase href={'/product/' + product().productID}>
                    <ImageView height={'100px'} image={product().images[0]}></ImageView>
                </ButtonBase>
                <VStack>
                    <Typography variant="subtitle1" align="left" component="div" gutterBottom>
                        {
                            product().title
                        }
                    </Typography>
                    <Typography variant="caption" align="left" component="div" sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                    }}>
                        {
                            product().details
                        }
                    </Typography>
                </VStack>
                <Typography variant="h6" gutterBottom component="div">
                    Price: {
                    props.workerProduct.price
                } RON
                </Typography>
            </HStack>
        </VStack>
    }

    return <>No represantation</>
}

export default CheckoutProductCell;
