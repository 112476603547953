import {Route, Routes, useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Alert} from "@mui/material";
import VStack from "../../Layout/VStack";
import Grid from "@mui/material/Grid";
import DashboardAssets from "../../Dashboard/DashboardAssets";
import DashboardCategories from "../../Dashboard/DashboardCategories";
import DashboardCategory from "../../Dashboard/DashboardCategory";
import DashboardVariations from "../../Dashboard/DashboardVariations";
import DashboardProducts from "../../Dashboard/DashboardProducts";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import HStack from "../../Layout/HStack";
import Box from "@mui/material/Box";
import Categories from "../../Categories/Categories";
import CategoryCell from "../../Categories/CategoryCell";
import Request from "../../../Network/Request";
import SeobleView from "../SeoView/SeobleView";

function CategoryView(props) {
    const { categoryID } = useParams();

    const [payments, setpayments] = useState([]);
    const [actions, setActions] = useState([]);

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    const [error, setError] = useState(null);
    const [content, setContent] = useState(0);
    const [categories, setCategories] = useState([]);

    useEffect(function () {
        var params = {}

        if (categoryID) {
            params.categoryID = categoryID
        }

        var categoryListRequest = new Request({
            name: "Category List",
            path: "/api/category/list",
            params : params
        });

        categoryListRequest.start(function (error, response) {
            if (!error) {
                setCategories(response.categories ?? [])
            }
        }.bind(this));
    }, [])

    return <VStack>
        <SeobleView></SeobleView>
        <HStack spacing={'0px'}>
            {
                categories.map(function (category) {
                    return <CategoryCell xs={3} sm={4} md={4} key={ category.categoryID } category={ category }></CategoryCell>
                })
            }
        </HStack>
    </VStack>
}

export default CategoryView;

