import React, {useEffect, useState} from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import Request from "../../../Network/Request";
import { SuperSEO } from "react-super-seo";
import {usePersistedState} from "../../Imports";

function SeobleView(props) {
    const [seo, setSeo] = usePersistedState("seo", {});

    return <SuperSEO title={ seo.title } description={ seo.description } lang={ seo.locale }>
        </SuperSEO>
}

export default SeobleView;
