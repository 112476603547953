import React, {useEffect, useState} from 'react';
import SectionView from "../Layout/SectionView";
import VStack from "../Layout/VStack";
import ImageView from "../ImageView";
import Request from "./../../Network/Request";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";

function Contact(props) {
    const [loaded, setLoaded] = useState(false);
    const [contactMethods, setContactMethods] = useState([]);

    useEffect(function () {
        if (loaded){
            return
        }

        var checkoutRequest = new Request({
            name: "Contact List",
            path: "/api/contactus/list",
            params : {}
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                setContactMethods(response.contactMethods)
                console.log(response)
                setLoaded(true)
            }
        }.bind(this));
    }, [loaded]);

    return <VStack spacing={'10px'}>
            {
                contactMethods.map(function (contactMethod) {
                    return <SectionView key={contactMethod.details}>
                        <VStack padding={'10px'} vertical={'center'}>
                            <ImageView md={1} xs={1} image={ contactMethod.icon }></ImageView>
                            <VStack md={9} xs={8} padding={'10px'}>
                                <Typography align={'left'} fontWeight={'bold'}>{ contactMethod.name }</Typography>
                                <Typography align={'left'}>{ contactMethod.details }</Typography>
                            </VStack>
                            <Button md={2} xs={2} variant={'contained'} onClick={function (params) {
                                window.location = contactMethod.link
                            }}>Open</Button>
                        </VStack>
                    </SectionView>
                })
            }
    </VStack>
}

export default Contact;