import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import ImageView from "../../../ImageView";
import SectionView from "../../../Layout/SectionView";
import VStack from "../../../Layout/VStack";
import Request from "../../../../Network/Request";
import {usePersistedState} from "../../../Imports";
import SeobleView from "../../SeoView/SeobleView";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HStack from "../../../Layout/HStack";
import CheckoutButtonView from "../CheckoutButtonView/CheckoutButtonView";
import { HighlightOff } from "@mui/icons-material";
function ProductsView(props) {
    let { categoryID } = useParams();

    const [workerProducts, setWorkerProducts] = useState(null)
    const [products, setProducts] = useState([])
    const [update, setUpdate] = useState(false)
    const [cart, setCart] = usePersistedState('shoppingCart', {
        products : []
    })

    useEffect(function () {
        loadPage()
    }, []);

    function loadPage() {
        var loadPage = new Request({
            name: "Product Page",
            path: "/api/shop/product/list",
            params: {
                categoryID : categoryID
            }
        });

        loadPage.start(function (error, response) {
            if (!error) {
                setProducts(response.products)
            }

        }.bind(this));
    }

    if (!products.length) {
        return <>Loading</>
    }

    function addToCart(product) {
        var tempCart = cart
        tempCart.products = cart.products ?? []
        tempCart.products.push(product)

        var price = 0
        var discountedPrice = 0
        tempCart.products.forEach(function (prod) {
            price += prod.price
            discountedPrice += prod.discountedPrice
        })


        tempCart.totalPrice = price
        tempCart.discountedPrice = discountedPrice

        setCart(tempCart)

        setUpdate(!update)

        console.log(tempCart)
    }

    function removeFromCart(product) {
        var tempCart = cart
        tempCart.products = cart.products.filter(function (element) {
            return element.productID != product.productID
        }) ?? []

        var price = 0
        var discountedPrice = 0
        tempCart.products.forEach(function (prod) {
            price += prod.price
            discountedPrice += prod.discountedPrice
        })

        tempCart.totalPrice = price
        tempCart.discountedPrice = discountedPrice

        setCart(tempCart)
        setUpdate(!update)
        console.log(tempCart)
    }

    function productButton(product) {
        var containsProduct = cart.products.filter(function (element) {
            return element.productID == product.productID
        }).pop()

        if (!containsProduct) {
            return <Button xs={6}  md={6} variant="contained" onClick={function () {
                addToCart(product)
            }}>
                <AddCircleIcon>
                </AddCircleIcon>
            </Button>
        }

        return <Button  xs={6} md={6} color={'error'} variant="contained" onClick={function () {
            removeFromCart(product)
        }}>
            <HighlightOff>
            </HighlightOff>
        </Button>
    }

    return <>
        <SeobleView></SeobleView>
        <VStack>
            {
                products.map(function (product) {
                    return <SectionView key={product.productID} >
                        <VStack padding={'5px'}>
                            <HStack padding={'5px'}>
                                <ImageView xs={4} md={3} lg={3} image={product.image} bgcolor={'#002211'} ></ImageView>
                                <VStack xs={8} md={7}>
                                    <Typography variant={'h5'} align={'left'}>{ product.title }</Typography>
                                    <Typography variant={'body1'}  align={'left'}>{ product.subtitle }</Typography>
                                    <Typography variant={'body2'} align={'left'}>{ product.details }</Typography>
                                    <Typography></Typography>
                                    <Typography></Typography>
                                    <Typography></Typography>
                                </VStack>
                                <VStack xs={6} md={2} >
                                    <Typography variant={'h5'} fontWeight={'bold'} align={'right'}>{ product.price } £</Typography>
                                    <Typography variant={'body1'} style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}} align={'right'}>{ product.discountedPrice } £</Typography>
                                    <HStack>
                                        <Typography xs={6} md={6}></Typography>
                                        {
                                            productButton(product)
                                        }
                                    </HStack>
                                </VStack>
                            </HStack>
                        </VStack>
                    </SectionView>
                })
            }
        </VStack>
        <CheckoutButtonView cart={cart} title={'Checkout'} completed={function () {
            window.location = '/check'
        }} ></CheckoutButtonView>
    </>
}

export default ProductsView;