import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import {ButtonBase, CardMedia, Rating, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import ImageView from "../../ImageView";
import Typography from "@mui/material/Typography";
import {useParams, useSearchParams} from "react-router-dom";
import Request from "../../../Network/Request";
import VariationCell from "./VariationCell";
import {Label} from "@mui/icons-material";

function VariationAdd(props) {
    let { categoryID, variationID } = useParams();
    const [variations, setVariations] = useState([]);
    const [variationTitle, setVariationTitle] = useState("");
    const [variationUser, setVariationUser] = useState(null);

    function getVariationID() {
        return variationID ?? props.variation.variationID
    }

    function getCategoryID() {
        return categoryID ?? props.categoryID
    }

    useEffect(function () {
        if (!variationUser) {
            return;
        }

        props.onAddUserVariation(variationUser)

    }, [variationUser]);

    function addVariation() {
        var variationAdd = new Request({
            name: "Variation New",
            path: "/api/variations/new",
            params : {
                title : variationTitle,
                categoryID : getCategoryID(),
                variationID : getVariationID()
            }
        });

        variationAdd.start(function (error, response) {
            if (!error) {
                setVariationUser(response.variationUser)
            }
        }.bind(this));
    }

    function changeVariatonTitle(event) {
        setVariationTitle(event.target.value)
    }

    return <Paper tran elevation={1}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item md={12}>
                    <label>Variation Title</label>
                    {
                        props.variation.name
                    }
                    <TextField id="outlined-basic" label="Variation Title" variant="outlined" fullWidth onChange={changeVariatonTitle}/>
                </Grid>
                <Grid item>

                </Grid>
                <Grid item>

                </Grid>
                <Grid item>
                    <button onClick={addVariation}>Adauga</button>
                </Grid>
            </Grid>
        </Paper>
}

export default VariationAdd;
