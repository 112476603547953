import React, {useEffect, useRef, useState} from 'react';
import Request from "../../Network/Request";
import Box from "@mui/material/Box";
import { CardMedia } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DropZone from "../Dropzone/Dropzone";
import Modal from "@mui/material/Modal";
import VStack from "../Layout/VStack";
import HStack from "../Layout/HStack";
import ImageView from "../ImageView";

function AssetCell(props) {
    var asset = props.asset

    if (!asset || !asset.urls) {
        return <></>
    }
    return <VStack>
        <HStack>
            <ImageView md={5} height={'200px'} image={asset.assetcdn ?? ""}></ImageView>
            <VStack md={2}>
                {
                    Object.keys(asset.urls).map(function (url) {
                        return <Button fullWidth key={url} variant='contained' onClick={function () {
                            var assetURL = asset.urls[url]
                            window.open(assetURL)
                            navigator.clipboard.writeText(assetURL)
                        }}>{url}</Button>
                    })
                }
                <Button onClick={function () {
                    window.open(asset.assetcdn ?? "")
                }}>Go to</Button>
            </VStack>
        </HStack>
    </VStack>
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function DashboardAssets(props) {
    const [payments, setpayments] = useState([]);
    const [actions, setActions] = useState([]);

    const [nodes, setNodes] = useState([]);
    const [assets, setAssets] = useState([]);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function loadAssets() {
        var loadCategories = new Request({
            name: "Assets",
            path: "/api/admin/asset/list"
        });

        loadCategories.start(function (error, response) {
            if (!error && response.assets) {
                setAssets(response.assets)
            }
        }.bind(this));
    }

    useEffect(function () {
        loadAssets()
    }, [])

    return <VStack>
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Button onClick={function (params) {
                    setOpen(false)
                }}>X</Button>
                <DropZone height={'200px'} onUploaded={function () {
                    handleClose()
                    loadAssets()
                }}></DropZone>
            </Box>
        </Modal>
        <Button variant={'contained'} onClick={handleOpen}>Upload</Button>
        {
            assets.map(function (asset) {
                return <AssetCell key={asset.assetID} asset={asset}></AssetCell>
            })
        }
    </VStack>
}

export default DashboardAssets;
/*
*
*  <AlertView error={error}></AlertView>
                <DropZone height={'200px'}></DropZone>*/