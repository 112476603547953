import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import {SuperSEO} from "react-super-seo";

function NotFound(props) {
    return <>
        <SuperSEO title={ "LiteApp - Continut Lipsa" } description={ "This content is not found" } lang="en">
        </SuperSEO>
        <Paper elevation={4}>
            <Typography variant="h5" component="div" >
                { "This content is not found" }
            </Typography>
        </Paper>
    </>
}

export default NotFound;


