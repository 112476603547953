import React, {useEffect, useState} from 'react';
import Request from "../../Network/Request";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {ButtonBase, Menu, MenuItem} from "@mui/material";
import Pocket from "pocketjs";
import {usePersistedState} from "../Imports";
import VStack from "../Layout/VStack";

function DebugView(props) {
    const [session, setSession] = usePersistedState("session", null);
    const [device, setDevice] = usePersistedState("device", null);
    const [selectedLocation, setSelectedLocation] = usePersistedState("selectedLocation", null);
    const [enabled, setEnabled] = usePersistedState("enabledLocation", false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function isDevelopment() {
        if (process.env) {
            return process.env.NODE_ENV == 'development'
        }

        return false
    }

    useEffect(function () {
        /*var checkoutRequest = new Request({
            name: "Workplaces",
            path: "/api/checkout/findworkplaces",
            params : {
                checkoutID : searchParams.get("checkoutID")
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {

                setCheckoutPlaces(response.checkoutplaces)

                console.log(response)
            }
        }.bind(this));
*/
    }, []);

    function clearSession() {
        setSession(null)
        handleClose()
    }

    function clearDevice() {
        setDevice(null)
        handleClose()
    }

    function clearLocation() {
        setEnabled(false)
        setSelectedLocation(null)
        handleClose()
    }

    function home() {
        window.location = '/'
        handleClose()
    }

    function test() {
        window.location = '/test'
    }

    if (isDevelopment()) {
        return <VStack padding={'100px'}>
            <Button id="basic-button"
                variant={'contained'}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>
                Debug
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                <MenuItem onClick={test}>Test</MenuItem>
                <MenuItem onClick={home}>Home</MenuItem>
                <MenuItem onClick={clearSession}>Clear Session</MenuItem>
                <MenuItem onClick={clearDevice}>Clear Device</MenuItem>
                <MenuItem onClick={clearLocation}>Clear Location</MenuItem>
            </Menu>
        </VStack>
    }

    return <></>
}

export default DebugView;
