import { useParams, useSearchParams } from "react-router-dom";
import React, {useEffect, useState} from "react";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import ImageView from "../../ImageView";
import SectionView from "../../Layout/SectionView";
import VStack from "../../Layout/VStack";
import HStack from "../../Layout/HStack";
import Request from "../../../Network/Request";

function NotificationsView(props) {
    let { productID } = useParams();

    const [notifications, setNotifications] = useState([])
    const [product, setProduct] = useState(null)

    useEffect(function () {
        loadPage()
    }, []);

    function loadPage() {
        var loadPage = new Request({
            name: "Product Page",
            path: "/api/notification/list"
        });

        loadPage.start(function (error, response) {
            if (!error) {
                setNotifications(response.notifications)
            }
        }.bind(this));
    }

    return <VStack padding={'10px'}>
                <VStack>
                    <Typography padding={'10px'} align={'left'} variant={'h5'}>Notifications</Typography>
                    {
                        notifications.map(function (notification) {
                            return  <SectionView key={notification.notificationID}>
                                <HStack padding={'10px'}>
                                    <ImageView md={1} image={notification.image}></ImageView>
                                    <VStack md={9}>
                                        <Typography fontWeight={'bold'} align={'left'}>{notification.title}</Typography>
                                        <Typography align={'left'}>{notification.message}</Typography>
                                    </VStack>
                                    <Button md={2} variant={'contained'}>Details</Button>
                                </HStack>
                            </SectionView>
                        })
                    }
                </VStack>
    </VStack>
}

export default NotificationsView;