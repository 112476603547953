import React, {useEffect, useState} from 'react';
import './App.css';
import ReservationBoard from "./Components/Worker/ReservationBoard";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from "./Components/Authentication/Login";
import Checkout from "./Components/Checkout/Checkout";
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import ReactGA from 'react-ga4';
import Home from "./Components/Home/Home";
import { Link } from "@mui/material";
import WorkplaceList from "./Components/Workplaces/WorkplaceList";
import CheckoutProductList from "./Components/Checkout/Products/CheckoutProductList";
import Category from "./Components/Category/Category";
import VariationList from "./Components/Checkout/Variation/VariationList";
import LoginCode from "./Components/Authentication/LoginCode";
import Profile from "./Components/Profile/Profile";
import Pocket from 'pocketjs'
import Request from "./Network/Request";
import InstallApp from "./Components/InstallApp/InstallApp";
import CookieConsent from "react-cookie-consent";
import Typography from "@mui/material/Typography";
import {usePersistedState, useSessionState} from "./Components/Imports";
import TopBar from "./Components/TopBar/TopBar";
import { sha256, sha224 } from 'js-sha256';
import hexgen from "hex-generator"
import NotFound from "./Components/NotFound-404/NotFound";
import FreqAskedQuestions from "./Components/Footer/FrequentlyAskedQuestions";
import BecomePartner from "./Components/Footer/BecomePartner";
import PrivacyPolicy from "./Components/Footer/PrivacyPolicy";
import Contact from "./Components/Footer/Contact";
import SiteMap from "./Components/Footer/SiteMap";
import LinkView from "./Components/Views/LinkView/LinkView";
import TestView from "./Components/Views/TestView/TestView";
import ProductView from "./Components/Views/ProductView/ProductView";
import WorkerProductView from "./Components/Views/WorkerProductView/WorkerProductView";
import WorkplaceView from "./Components/Views/WorkplaceView/WorkplaceView";
import CheckoutView from "./Components/Views/CheckoutView/CheckoutView";
import ReservationsView from "./Components/Views/ReservationsView/ReservationsView";
import WorkplaceListView from "./Components/Views/WorkplaceListView/WorkplaceListView";
import LayoutTestView from "./Components/Views/LayoutTestView/LayoutTestView";
import VariationsView from "./Components/Views/VariationsView/VariationsView";
import OrderDetailsView from "./Components/Views/OrderDetailsView/OrderDetailsView";
import ReservationView from "./Components/Views/ReservationView/ReservationView";
import LoginView from "./Components/Views/LoginView/LoginView";
import LoginPhoneNumber from "./Components/Authentication/LoginPhoneNumber";
import LoginEmail from "./Components/Authentication/LoginEmail";
import CenterStack from "./Components/Layout/CenterStack";
import VStack from "./Components/Layout/VStack";
import HStack from "./Components/Layout/HStack";
import DashboardView from "./Components/Views/DashboardView/DashboardView";
import NotificationsView from "./Components/Views/NotificationsView/NotificationsView";
import getUserLocale from 'get-user-locale';
import CategoryView from "./Components/Views/CategoryView/CategoryView";
import SeoView from "./Components/Views/SeoView/SeoView";
import VariationNewView from "./Components/Views/VariationsView/VariationNewView";
import ReservationsHistoryView from "./Components/Views/ReservationsView/ReservationsHistoryView";
import FooterView from "./Components/Views/FooterView";
import Button from "@mui/material/Button";
import DebugView from "./Components/Debug/DebugView";
import ProductsView from "./Components/Views/Shop/ProductsView/ProductsView";
import ShopCheckoutView from "./Components/Views/Shop/ShopCheckoutView/ShopCheckoutView";
import ShopOrderDetailsView from "./Components/Views/Shop/OrderDetailsView/OrderDetailsView";
import ThankYouView from "./Components/Views/Shop/ThankYouView/ThankYouView";

var trackingID = ""

if (process.env.NODE_ENV === 'production') {
    trackingID = "G-ZJFJMX2BL6"
} else {
    trackingID = "G-45HFSD2E4F"
}

ReactGA.initialize([{
        trackingId: trackingID
}]);

var paddingSX = {
    display:
        {
            xs: 'none',
            sm: 'none',
            md: 'block'
        }
}

function App() {
    const [hover, setHover] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [session, setSession] = usePersistedState('session', null);
    const [device, setDevice] = usePersistedState('device', null);
    const [user, setUser] = usePersistedState('user', null);
    const [statusHash, setStatusHash] = usePersistedState('statusHash', "unknown");
    const [translations, setTranslations] = usePersistedState('translations', {});

    function toggleHoverState() {
        setHover(!hover)
    }

    var pocket = new Pocket()
    pocket.restore()
    var system = pocket.collection('system')
    system.remove()

    useEffect(function () {
        console.log("📀 Environment:" + process.env.NODE_ENV)

        appStatus()
    }, [])

    useEffect(function () {
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(function () {
        if (!session && !loading) {
            setLoading(true)
            loginGuest()
        }
        else {
        }
    }, [session, loading])

    function appStatus() {
        var locale = getUserLocale()

        locale = "ro"

       var appStatusRequest = new Request({
            name: "App Status",
            path: "/appstatus",
            params : {
                languageCode : locale,
                statushash : statusHash
            }
        });

        appStatusRequest.start(function (error, response) {
            if (!error) {
                var appStatus = response.appStatus

                if (appStatus.hash != statusHash) {
                    setStatusHash(appStatus.hash)
                    var respTranslations = response.translations
                    var trans = {}
                    respTranslations.forEach(function (translation) {
                        trans[translation.key] = translation.translation
                    }.bind(this))
                    setTranslations(trans)
                }
            }
        }.bind(this));
    }

    function loginGuest () {
        if (!device) {
            console.log("❌ No device: Generating one..")
            var device = {}
            device.deviceID = hexgen(128);
            setDevice(device)
            console.log("Generated: " + device.deviceID)
        }

        var secretKey = "bc747e2841" + "d5a2583a28149438ffa" + "6bfcb91ed99c" + "4d1a2a26080fa2fb" + "2ea" + "a00680d0f3e49097e7" + "8f343805c28f7b3cb17579"
        var verification = String(sha256(device.deviceID + secretKey))
        var loginGuest = new Request({
            name: "Login Guest",
            path: "/authenticate/loginauto",
            params : {
                deviceID : device.deviceID,
                verification : verification
            }
        });

        loginGuest.start(function (error, response) {
            if (!error) {
                console.log(response.session)
                setUser(response.user)
                setSession(response.session)
            }

            setLoading(false)
        }.bind(this));
    }

    function App() {
        if (session) {
            return <>
                <AppBar style={{ margin: 0 }}>
                    <TopBar></TopBar>
                </AppBar>
            <Grid marginTop={'60px'} container direction="row" justifyContent="flex-start" alignItems="flex-start" alignItems="stretch" minHeight={ window.innerHeight - 300}>
                <CenterStack>
                    <Router>
                        <Routes>
                            {
                                //MoftRS
                            }
                            <Route path="/products/:categoryID" element={<ProductsView/>}/>
                            <Route path="/check" element={<ShopCheckoutView/>}/>
                            <Route path="/order" element={<ShopOrderDetailsView/>}/>
                            <Route path="/thankyou" element={<ThankYouView/>}/>
                            {
                                //LiteApp
                            }
                            <Route path="/link/:linkID" element={<LinkView/>}/>
                            <Route path="/product/:productID" element={<ProductView/>}/>
                            <Route path="/wp/:workerProductID" element={<WorkerProductView/>}/>
                            <Route path="/workplace/:workplaceID" element={<WorkplaceView/>}/>
                            <Route path="/checkoutview/:checkoutID" element={<CheckoutView/>}/>
                            <Route path="/reservations" element={<ReservationsView/>}/>
                            <Route path="/placelist/:keyword" element={<WorkplaceListView/>}/>
                            <Route path="/layoutTest" element={<LayoutTestView/>}/>
                            <Route path="/variations" element={<VariationsView/>}/>
                            <Route path="/variation/new" element={<VariationNewView/>}/>
                            <Route path="/ordersummary/:checkoutID" element={<OrderDetailsView/>}/>
                            <Route path="/reservation/:reservationID" element={<ReservationView/>}/>
                            <Route path="/login" element={<LoginView/>}/>
                            <Route path="/loginsms" element={<LoginPhoneNumber/>}/>
                            <Route path="/loginemail" element={<LoginEmail/>}/>
                            <Route path="/dashboard" element={ <DashboardView/>}/>
                            <Route path="/dashboard/*" element={ <DashboardView/>}/>
                            <Route path="/checkout" element={<CheckoutView/>}/>
                            <Route path="/checkout/:linkID" element={<CheckoutView/>}/>
                            <Route path="/notifications" element={<NotificationsView/>}/>
                            <Route path="/venue/:workplaceID" element={<WorkplaceView/>}/>

                            <Route path="/reservations/history" element={<ReservationsHistoryView/>}/>

                            <Route exact path="/reservations" element={ <ReservationBoard style={{ height : '100%', backgroundColor : 'yellow'}}/>}/>
                            <Route path="/login" element={<Login/>}/>

                            <Route path="/test" element={<TestView/>}/>

                            <Route path="/profile" element={<Profile/>}/>
                            <Route path="/logincode/:credentialsID" element={<LoginCode/>}/>
                            <Route path="/checkout1" element={<Checkout/>}/>
                            <Route path="/category/:categoryID" element={<CategoryView/>}/>
                            <Route path="/workplaces/:checkoutID" element={<WorkplaceList/>}/>

                            <Route path="/products/c/:checkoutID" element={<CheckoutProductList/>}/>
                            <Route path="/products/w/:workplaceID" element={<CheckoutProductList/>}/>
                            <Route path="/categorylist/:categoryID" element={<Category/>}/>
                            <Route path="/variationlist/:categoryID" element={<VariationList/>}/>
                            <Route path="/frequently-asked-questions" element={<FreqAskedQuestions/>}></Route>
                            <Route path="/become-a-partner" element={<BecomePartner/>}></Route>
                            <Route path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
                            <Route path="/contact" element={<Contact/>}></Route>
                            <Route path="/sitemap" element={<SiteMap/>}></Route>
                            <Route path="/install-app" element={<InstallApp/>}/>

                            <Route path="/:keyword" element={<SeoView/>}/>

                            <Route path="/" element={<Home/>}/>
                            <Route path="/notfound" element={<NotFound></NotFound>}/>
                        </Routes>
                    </Router>
                </CenterStack>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" bgcolor={'#d03636'} sx={paddingSX}>
                <DebugView></DebugView>
            </Grid>
                <CookieConsent location="bottom"
                    buttonText="Am inteles"
                    cookieName="agreecookie"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "14px" }}
                    expires={150}>
                    Acest website foloseste cookies pentru a imbunatatii experienta utilizatorului.{" "}
                </CookieConsent>
            </>
        }
        else
        {
            return <label>Loading...</label>
        }
    }

    return (
        <div className="App">
            {
                App()
            }
        </div>
    );
}

export default App;
