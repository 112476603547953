import React from 'react';
import moment from "moment";

class ReservationCell extends React.Component
{
    constructor(props)
    {
        super(props);

        var reservation = this.props.reservation;
        var startDate = moment(reservation.startDate);
        var endDate = moment(reservation.endDate);

        var duration = moment.duration(endDate.diff(startDate));
        var scale = 2;

        this.reservationTimeframe = duration.asMinutes() * scale;
    }

    componentDidMount()
    {

    }

    render()
    {
        return (
            <div style={{  borderRadius : 5, marginTop : 2 , width : 400, height : this.reservationTimeframe, backgroundColor: "blue"}}>
                <div style={{ backgroundColor : "yellow" }}>
                    <h4> {'#' + this.props.reservation.number}}</h4>
                </div>
            </div>
        );
    }
}

export default ReservationCell;
