import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Alert, Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import SectionView from "../../Layout/SectionView";
import VStack from "../../Layout/VStack";
import HStack from "../../Layout/HStack";
import Grid from "@mui/material/Grid";
import ScreenCenter from "../../Layout/ScreenCenter";
import {usePersistedState} from "../../Imports";
import moment from "moment";

function LocationView(props) {
    let { linkID } = useParams();
    const [selectedLocation, setSelectedLocation] = usePersistedState("selectedLocation", null);
    const [enabled, setEnabled] = usePersistedState("enabledLocation", false);
    const [laterDate, setLaterDate] = usePersistedState("laterDate", null);

    useEffect(function () {
        if (enabled) {
            navigator.geolocation.getCurrentPosition(function(position) {
                if (!position || !position.coords) {
                    return
                }

                var positionCoords = {
                    latitude : position.coords.latitude,
                    longitude : position.coords.longitude,
                    accuracy : position.coords.accuracy
                }

                setSelectedLocation(positionCoords)

                if (props.onChangedLocation) {
                    props.onChangedLocation(positionCoords)
                }

                console.log("📍 Updated Location: ")
                console.log(positionCoords)
            });
        }
    }, [enabled]);

    function enableLocation() {
        setEnabled(true)
    }

    function maybeLater() {
        setLaterDate(new Date())
    }

    return <></>

    if (enabled) {
        return <VStack paddingTop={'10px'} >
            <Alert md={12} severity="success" align={'left'}>Your location is currently enabled. </Alert>
        </VStack>
    }

    if (laterDate) {
        var currentDate = moment(new Date())
        var duration = moment.duration(currentDate.diff(laterDate));
        var diffHours = duration.asHours();
        var diffMinutes = duration.asMinutes();

        if (diffMinutes < 1) {
            return <></>
        }
    }

    return <SectionView paddingTop={'10px'}>
        <VStack padding={'10px'}>
            <Alert md={12} severity="warning" align={'left'}>Enable location services to unlock a range of personalized features and services tailored to your area. Discover nearby attractions, find local recommendations, and enjoy a more customized app experience.</Alert>
            <HStack>
                <Button md={6}  variant="outlined"  onClick={maybeLater}>Maybe later</Button>
                <Button md={6}  variant="contained"  onClick={enableLocation}>Enable Location</Button>
            </HStack>
        </VStack>
    </SectionView>
}

export default LocationView;