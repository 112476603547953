import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CheckoutPayment from "../../Checkout/CheckoutPayment";
import ImageView from "../../ImageView";
import Paper from "@mui/material/Paper";
import SectionView from "../../Layout/SectionView";
import VStack from "../../Layout/VStack";
import HStack from "../../Layout/HStack";
import JSONView from "../../Layout/JSONView";
import {SuperSEO} from "react-super-seo";
import WorkplaceCell from "../../Workplaces/WorkplaceCell";
import Request from "../../../Network/Request";
import LocationView from "../LocationView/LocationView";
import SeobleView from "../SeoView/SeobleView";

function WorkplaceListView(props) {
    let { keyword } = useParams();
    const [ workplaces, setWorkplaces] = useState([]);

    useEffect(function () {
        var options = {
            name: "Seo List",
            path: "/api/seo/list"
        }

        if (keyword) {
            options.params = {
                keyword : keyword
            }
        }

        var seoListRequest = new Request(options);
        seoListRequest.start(function (error, response) {
            if (!error) {
                var seo = response.seo
                if (response.workplaces)
                {
                    setWorkplaces(response.workplaces)
                }
            }
            else
            {
                if (error.error_code == '867481') {
                    //window.location = '/notfound'
                }
            }
        }.bind(this));
    }, []);

    function onWorkplaceDetails() {
        window.location = "/workplace/asdasd"
    }

    return <VStack>
        <SeobleView></SeobleView>
        <LocationView></LocationView>
        {
            workplaces.map(function (workplace) {
                    if (workplace && workplace.workplaceID) {
                        return <SectionView key={ workplace.workplaceID }>
                            <WorkplaceCell workplace={workplace} showDetailsButton={true}></WorkplaceCell>
                        </SectionView>
                    }
            })
        }
    </VStack>
}

export default WorkplaceListView;