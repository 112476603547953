import React, {useEffect, useState} from 'react';
import VStack from "../Layout/VStack";
import CategoryView from "../Views/CategoryView/CategoryView";
import Button from "@mui/material/Button";

function Home(props) {
    useEffect(function () {

    }, []);

    useEffect(function () {

    }, []);

    return <VStack>
        <CategoryView></CategoryView>
    </VStack>
}

export default Home;
