import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import ImageView from "../../ImageView";
import SectionView from "../../Layout/SectionView";
import VStack from "../../Layout/VStack";
import Request from "../../../Network/Request";
import WorkplaceCell from "../../Workplaces/WorkplaceCell";
import VariationCell from "../../Checkout/Variation/VariationCell";
import {usePersistedState} from "../../Imports";
import SeobleView from "../SeoView/SeobleView";

function ProductView(props) {
    let { productID } = useParams();

    const [workerProducts, setWorkerProducts] = useState(null)
    const [product, setProduct] = useState(null)

    useEffect(function () {
        loadPage()
    }, []);

    function loadPage() {
        var loadPage = new Request({
            name: "Product Page",
            path: "/api/website/productpage",
            params: {
                productID : productID
            }
        });

        loadPage.start(function (error, response) {
            if (!error) {
                setWorkerProducts(response.workerProducts)
                setProduct(response.product)
            }
        }.bind(this));
    }

    if (!product || !workerProducts) {
        return <>Loading</>
    }

    var workplaces = []

    workerProducts.forEach(function (workerProduct) {
        var workplace = workerProduct.worker.workplace

        workplaces.push(workplace)
    })

    var seen = {};
    workplaces = workplaces.filter(function (workplace) {
        var workplaceID = workplace.workplaceID
        return seen[workplaceID] ? false : (seen[workplaceID] = true)
    })

    return <>
        <SeobleView></SeobleView>
        <VStack>
            <SectionView>
                <VStack>
                    <ImageView md={4} image={product.image} ></ImageView>
                    <VStack md={4} padding={'10px'}>
                        <Typography variant={'h4'} align={'left'}>{ product.title }</Typography>
                        <Typography variant={'h5'} align={'left'}>{ product.subtitle }</Typography>
                        <Typography variant={'body2'} align={'left'}>{ product.details }</Typography>
                    </VStack>
                </VStack>
                {
                    workplaces.map(function (workplace) {
                        return <VStack>
                            <WorkplaceCell md={6} workplace={workplace}></WorkplaceCell>
                            {
                                workerProducts.filter(function (workerProduct) {
                                    return workerProduct.worker.workplace.workplaceID == workplace.workplaceID
                                }).map(function (workerProduct) {
                                    return <WorkerProductViewLive workerProduct={workerProduct}></WorkerProductViewLive>
                                })
                            }
                        </VStack>
                    })
                }
            </SectionView>
        </VStack>
    </>
}

function WorkerProductViewLive(props) {
    const [selectedCategory, setSelectedCategory] = usePersistedState("selectedCategory", {});
    const [selectedWorkplace, setSelectedWorkplace] = usePersistedState("selectedWorkplace", {});
    const [selectedProduct, setSelectedProduct] = usePersistedState("selectedProduct", {});
    const [selectedVariation, setSelectedVariation] = usePersistedState("selectedVariation", {});

    var workerProduct = props.workerProduct

    var title = workerProduct.title
    var worker = workerProduct.worker
    var workplace = worker.workplace

    return <VStack>
        <SeobleView></SeobleView>
        <VStack>
            <Typography align={'left'}>{ worker.name }</Typography>
        </VStack>
        <VStack md={8}>
            <VariationCell variation={workerProduct.variation}></VariationCell>
        </VStack>
        <VStack md={2}>
            <VStack>
                <Typography>Price: { workerProduct.price } RON</Typography>
                <Typography>{ workerProduct.duration } Minutes</Typography>
            </VStack>
        </VStack>
        <Button md={2} variant={'contained'} onClick={function (params) {
            setSelectedWorkplace(workplace)
            setSelectedCategory(workerProduct.worker.category)
            window.location = "/variations"
        }}>Buy now</Button>
    </VStack>
}

export default ProductView;