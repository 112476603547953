import Typography from "@mui/material/Typography";
import React from "react";

function DateView(props) {
    var date = props.date

    function getDate() {
        return new Date(date)
    }

    return <Typography align={'left'} variant={'h4'}>
        { getDate().toLocaleDateString([],{day: 'numeric', month:'long', year : 'numeric'}) }
    </Typography>
}
export default DateView;