import React, {useEffect, useState} from 'react';
import Request from "../../Network/Request";
import {useParams, useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import CheckoutPlaceCell from "./CheckoutPlaceCell";
import {LinearProgress} from "@mui/material";
import Box from "@mui/material/Box";
import { usePersistedState } from "../Imports";

function WorkplaceList(props) {
    let { checkoutID } = useParams();
    const [loaded, setLoaded] = useState(false);
    const [checkoutPlace, setcheckoutPlace] = useState(null);
    const [checkoutPlaces, setcheckoutPlaces] = useState([]);
    const [checkout, setCheckout] = usePersistedState("checkout", {});
    const [loading, setloading] = useState(false);

    function getCheckoutID() {
        return props.checkout ? props.checkout.checkoutID : checkoutID;
    }

    useEffect(function () {
        if (!props.checkout && !checkoutID) {
            return
        }

        setcheckoutPlaces([])
        setloading(true)

        var checkoutRequest = new Request({
            name: "Workplace List",
            path: "/api/checkout/findworkplaces",
            params : {
                checkoutID : getCheckoutID()
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                setcheckoutPlaces(response.checkoutplaces)
                setLoaded(true)
                setloading(false)
            }
        }.bind(this));
    }, [props.checkout]);

    useEffect(function () {
        if (!checkoutPlace) {
            return;
        }

        var checkoutRequest = new Request({
            name: "Workplace List",
            path: "/api/checkout/worker",
            params : {
                checkoutID : getCheckoutID(),
                workerID : checkoutPlace.worker.workerID
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                setCheckout(response.checkout)
                setTimeout(function () {
                    window.location = '/products/c/' + response.checkout.checkoutID

                }, 100)
            }
            else {
                console.log(error);
            }
        }.bind(this));

    }, [checkoutPlace]);

    return <>
        {
            loading ? <Box sx={{ width: '100%'}} paddingBottom={'10px'}>
                <LinearProgress />
            </Box> : <></>
        }
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" bgcolor={'#FFFFFF'}  paddingBottom={'10px'} >
            <Grid container paddingTop={'10px'}>

            </Grid>
            {
                checkoutPlaces.map(function(checkoutPlace) {
                    return <Grid  key={ checkoutPlace.workplace.workplaceID } container md={12} direction="column" justifyContent="flex-start" alignItems="stretch" padding={'10px'} >
                        <CheckoutPlaceCell checkoutPlace={checkoutPlace} selectedCheckoutplace={function (checkoutPlace) {
                            setcheckoutPlace(checkoutPlace)
                        }}> </CheckoutPlaceCell>
                    </Grid>
                })
            }
        </Grid>
    </>
}

export default WorkplaceList;
