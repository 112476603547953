import React, {useEffect, useState} from 'react';
import Request from "../../Network/Request";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Payments from "./Payments";
import Grid from "@mui/material/Grid";
import Variation from "./Variation/Variation";
import CheckoutPayment from "./CheckoutPayment";
import {useParams} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import WorkplaceList from "../Workplaces/WorkplaceList";
import CheckoutProducts from "./CheckoutProducts";
import WorkplaceCell from "../Workplaces/WorkplaceCell";
import TimeView from "../TimeView";
import VStack from "../Layout/VStack";
import SectionView from "../Layout/SectionView";
import CenterStack from "../Layout/CenterStack";

const modals = {
    modal1 : <WorkplaceList/>
}

function Checkout(props) {
    let { checkoutID } = useParams();
    const [checkout, setCheckout] = useState(null);
    const [category, setCategory] = useState(null);
    const [checkoutReservation, setcheckoutReservation] = useState(null);
    const [modalContent, setModalContent] = useState(0);
    const handleClose = () => setModalContent(0);

    useEffect(function () {
        if (!checkoutID) {
            return
        }

        var checkoutRequest = new Request({
            name: "Checkout",
            path: "/api/checkout/start",
            params : {
                checkoutID : checkoutID
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                setCheckout(response.checkout)

                console.log(response)
            }
        }.bind(this));

    }, [category]);

    useEffect(function () {
        if (!checkoutReservation) {
            return
        }

        var checkoutRequest = new Request({
            name: "Make Reservation",
            path: "/api/reservation/new",
            params : {
                checkoutID : checkoutReservation.checkoutID
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                var reservationID = response.reservation.reservationID;

                window.location = "/reservation/" + reservationID

                console.log(response)
            }
        }.bind(this));

    }, [checkoutReservation]);

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(5),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    if (checkout) {
        return <VStack>
            <SectionView>
                <WorkplaceCell workplace={checkout.worker.workplace}></WorkplaceCell>
            </SectionView>
            <SectionView>
                <Variation variationUser={checkout.variationUser}></Variation>
            </SectionView>
            <SectionView>
                <CheckoutProducts variant={"ordersummary"} checkout={checkout}></CheckoutProducts>
            </SectionView>
            <SectionView>
                <TimeView startDate={checkout.startDate} duration={checkout.duration}></TimeView>
            </SectionView>
            <SectionView>
                <CheckoutPayment paymentType={checkout.paymentType}></CheckoutPayment>
            </SectionView>
            <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
                <CenterStack>
                    <VStack>
                        <Button fullWidth variant={'contained'} onClick={function () {
                            setcheckoutReservation(checkout)
                        }}>Buy now</Button>
                    </VStack>
                </CenterStack>
            </AppBar>
        </VStack>
    }
    else
    {
        return <>Loading</>
    }
}

export default Checkout;
