import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CheckoutPayment from "../../Checkout/CheckoutPayment";
import ImageView from "../../ImageView";
import Paper from "@mui/material/Paper";
import SectionView from "../../Layout/SectionView";
import VStack from "../../Layout/VStack";
import HStack from "../../Layout/HStack";
import JSONView from "../../Layout/JSONView";
import Request from "../../../Network/Request";
import WorkplaceCell from "../../Workplaces/WorkplaceCell";
import VariationCell from "../../Checkout/Variation/VariationCell";
import ProductView from "../ProductView/ProductView";
import ProductCell from "../../Checkout/ProductCell";

function WorkerProductView(props) {
    let { workerProductID } = useParams();

    const [workerProduct, setWorkerProduct] = useState(null)

    useEffect(function () {
        loadPage()
    }, []);

    function loadPage() {
        var loadPage = new Request({
            name: "Worker Product Page",
            path: "/api/website/workerproductpage",
            params: {
                workerProductID : workerProductID
            }
        });

        loadPage.start(function (error, response) {
            if (!error) {

                setWorkerProduct(response.workerProduct)

                //window.location = "/dashboard/category/" + response.category.categoryID
            }
        }.bind(this));
    }

    if (!workerProduct) {
        return <>Loading</>
    }

    var product = workerProduct.product

    return <>
        <VStack>
            <SectionView md={12}>
                <VStack>
                    <Typography>{ product.title }</Typography>
                    <Typography>{ product.subtitle }</Typography>
                    <Typography>{ product.details }</Typography>
                </VStack>
            </SectionView>
            <SectionView>
                <WorkplaceCell workplace={ workerProduct.worker.workplace }></WorkplaceCell>
            </SectionView>
            <SectionView>
                <VariationCell variation={ workerProduct.variation }></VariationCell>
            </SectionView>
            <SectionView md={12}>
                <VStack>
                    <Typography>This is the Worker Product Ratings</Typography>
                </VStack>
            </SectionView>
            <SectionView>
                <VStack>
                    <Button variant="contained">Go to checkout</Button>
                </VStack>
            </SectionView>
        </VStack>
    </>
}

export default WorkerProductView;