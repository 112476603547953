import React, {useEffect, useRef, useState} from 'react';
import Request from "../../Network/Request";
import Box from "@mui/material/Box";
import {ButtonBase, Switch, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ImageView from "../ImageView";
import Modal from "@mui/material/Modal";
import {useSearchParams} from 'react-router-dom';

function CategorySelector(props) {
    const [categories, setCategories] = useState([]);

    useEffect(function () {
        loadCategory()
    }, []);

    function loadCategory() {
        var loadCategories = new Request({
            name: "Workplaces",
            path: "/api/admin/categories/list"
        });

        loadCategories.start(function (error, response) {
            if (!error && response.categories) {
                setCategories(response.categories)
                //setLoaded(true)
            }
        }.bind(this));
    }

    return <>
        <Grid container alignItems="stretch" bgcolor={'#ffffff'}>
    <Grid item md={12}>            Category selector
    </Grid>
            <Grid item md={12}>            {
                categories.map(function (category) {
                    return <>
                        <Button variant={"outlined"} onClick={function () {
                            props.onCategorySelected(category)
                        }}>
                            <ImageView width={'100px'} height={'50px'} image={category.image}></ImageView>
                            {category.name} </Button>
                    </>
                })
            }
            </Grid>

        </Grid>
    </>
}

function VariationCell(props) {
    var variation = props.variation

    useEffect(function () {

    }, []);

    return <>
        <Grid container alignItems="stretch" bgcolor={'#ffffff'}>
            <Grid item md={12}>
                <ButtonBase onClick={function () {
                    props.onSelectedVariation(variation)
                }}>
                <ImageView image={variation.image} height={'50px'}></ImageView>
                <Typography>{variation.name}</Typography>
                <Typography>{variation.details}</Typography></ButtonBase>
            </Grid>
        </Grid>
    </>
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function DashboardVariations(props) {
    const [payments, setpayments] = useState([]);
    const [actions, setActions] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState(null);
    const [variations, setVariations] = useState([]);
    const [variation, setVariation] = useState(null);

    const [image, setImage] = useState(null);
    const [name, setName] = useState(null);
    const [details, setDetails] = useState(null);

    const [state, setState] = React.useState({
        isEnabled: true,
        isActive: false
    });

    const [options, setOptions] = useState({});

    const handleChange = (event) => {
        setOptions({
            ...options,
            [event.target.id]: event.target.value,
        });
    };

    const handleChangeBool = (event) => {
        setOptions({
            ...options,
            [event.target.id]: Boolean(event.target.value),
        });
    };

    function loadVariations(categoryID) {
        console.log("Load" + categoryID)

        var loadCategory = new Request({
                name: "Category details",
                path: "/api/admin/variation/list",
                params : {
                    categoryID : categoryID
                }
        });

        loadCategory.start(function (error, response) {
                if (!error && response.variations) {
                    setVariations(response.variations)
                    //updateCategory(response.category)
                    //setCategories(response.categories)
                    //setLoaded(true)
                }
            console.log(error)

        }.bind(this));
    }

    useEffect(function () {
        if (variation) {
            setOptions({
                name : variation.name,
                image : variation.image,
                isEnabled : variation.isEnabled,
                isDefault : variation.isDefault
            })
        }

        setOpen(variation != null)
    }, [variation])

    useEffect(function () {
        var selectedCategoryID = searchParams.get('selectedCategory')
        if (selectedCategoryID) {
            loadVariations(selectedCategoryID)
        }
    }, [searchParams])

    function handle(e) {
        console.log(e.target)
    }

    function handleClose() {
        setVariation(null)
    }

    function saveVariation() {
        var saveVariation = new Request({
            name: "Save Variation",
            path: "/api/admin/variation/save",
            params : {
                variationID : variation.variationID,
                categoryID : category.categoryID,
                options : options
            }
        });

        saveVariation.start(function (error, response) {
            if (!error && response.variation) {

                setVariation(null)
                loadVariations(category.categoryID)
                //updateCategory(response.category)
                //setCategories(response.categories)
                //setLoaded(true)
            }
            console.log(error)

        }.bind(this));
    }

    return <>
        {
/*
            <CategorySelector onCategorySelected={function (category) {
                setCategory(category)
            }}></CategorySelector>
*/
        }
        <Grid item md={12}>
            <Button variant={"outlined"} onClick={function () {
                setVariation({})
            }}>Add new variation</Button>
        </Grid>
        {
            variations.map(function (variation) {
                return <VariationCell variation={variation} onSelectedVariation={function (variation) {
                    setVariation(variation)
                }}></VariationCell>
            })
        }
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                {
                    variation != null ? <>
                        <TextField fullWidth id="name" label="Name" variant="outlined" value={options.name} onChange={handleChange} />
                    <TextField fullWidth id="image" label="Image" variant="outlined" value={options.image} onChange={handleChange}/>
                        <Typography>Enabled</Typography>
                        <Switch checked={options.enabled} onChange={handleChangeBool} id="enabled" />
                        <Typography>Default</Typography>
                        <Switch checked={options.isDefault} onChange={handleChangeBool} id="isDefault" />
                    <Button variant={'contained'} onClick={function () {
                        saveVariation()
                    }} >Save</Button></>: <></>
                }
            </Box>
        </Modal>
    </>
}

export default DashboardVariations;
