import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React from "react";

function HStack(props) {
    var children = React.Children.toArray(props.children);
    var childrenCount = children.length
    var childWidth = 12 / childrenCount

    return <Grid container direction="row" justifyContent="flex-start" alignItems="center" bgcolor={'#ffffff'} padding={props.padding } paddingTop={props.paddingTop} sx={props.sx}>
            {
                children.map(function (child, index) {
                    if (!child.props) {
                        return  <>Nothing here</>
                    }

                    return <Grid key={child.key} item xs={child.props.xs} sm={child.props.sm} md={child.props.md} lg={child.props.lg} xl={child.props.xl}>
                        {
                            child
                        }
                    </Grid>
                })
            }
    </Grid>
}

export default HStack;