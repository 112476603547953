import VStack from "../../Layout/VStack";
import HStack from "../../Layout/HStack";
import ImageView from "../../ImageView";
import Typography from "@mui/material/Typography";
import TranslationView from "../CustomViews/TranslationView";
import {Button} from "@mui/material";
import React from "react";

function PaymentCell(props) {
    var payment = props.payment

    function selectButton() {
        if (props.onSelect) {
            return <Button md={2} variant={'contained'} onClick={function (params) {
                props.onSelect(payment)
            }}>Select</Button>
        }

        return <></>
    }

    return <VStack padding={'10px'}>
        <HStack>
            <ImageView md={1} xs={2} image={payment.image}></ImageView>
            <VStack md={9} xs={10} paddingLeft={'10px'} spacing={'0px'}>
                <Typography align={'left'} variant="subtitle1" component="div" gutterBottom>
                    <TranslationView>
                        { payment.name }
                    </TranslationView>
                </Typography>
                <Typography align={'left'} variant="subtitle2" component="div" gutterBottom>
                    <TranslationView>
                        { payment.details }
                    </TranslationView>
                </Typography>
            </VStack>
            {
                selectButton()
            }
        </HStack>
    </VStack>
}

export default PaymentCell