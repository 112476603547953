import React, {useEffect, useRef, useState} from 'react';
import Request from "../../Network/Request";
import Box from "@mui/material/Box";
import { CardMedia, Switch, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import SectionView from "../Layout/SectionView";
import HStack from "../Layout/HStack";
import VStack from "../Layout/VStack";

function DashboardCategory(props) {
    const { categoryID } = useParams()

    const [category, setCategory] = useState(null);
    const [image, setImage] = useState(null);
    const [name, setName] = useState(null);
    const [details, setDetails] = useState(null);

    const [state, setState] = React.useState({
        isEnabled: true,
        isActive: false,
        isFinal : false
    });

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const [error, setError] = useState(null);

    useEffect(function () {
        loadCategory()
    }, [])

    function loadCategory() {
            var loadCategory = new Request({
                name: "Category details",
                path: "/api/admin/categories/details",
                params : {
                    categoryID : categoryID
                }
            });

        loadCategory.start(function (error, response) {
                if (!error && response.category) {
                    updateCategory(response.category)
                    //setCategories(response.categories)
                    //setLoaded(true)
                }

        }.bind(this));
    }

    function updateCategory(category) {
        setImage(category.image)
        setName(category.name)
        setDetails(category.details)
        setCategory(category)
        setState({
            isEnabled: category.isEnabled,
            isActive: category.isActive,
            isFinal: category.isFinal
        })
    }

    function saveCategory() {
        var saveCategory = new Request({
            name: "Category save",
            path: "/api/admin/categories/save",
            params : {
                categoryID : categoryID,
                options : {
                    image : image,
                    isActive : state.isActive,
                    isEnabled : state.isEnabled,
                    isFinal : state.isFinal,
                    name : name,
                    details : details
                }
            }
        });

        saveCategory.start(function (error, response) {
            if (!error && response.category) {
                loadCategory()
            }
            console.log(error)

        }.bind(this));
    }
    
    function handle(e) {
        console.log(e.target)
    }

    if (!category) {
        return <label>Loading</label>
    }

    return <SectionView>
        <VStack padding={'10px'}>
            <Typography align={'left'}>Title</Typography>
            <TextField fullWidth value={name} onChange={function (event) {
                setName(event.target.value)
            }}></TextField>
            <Typography align={'left'}>Subtitle</Typography>

            <TextField fullWidth value={details} onChange={function (event) {
                setDetails(event.target.value)
            }}></TextField>
            <Box padding={'3px'}>
                <CardMedia key={category.categoryID} sx={{ borderRadius: 2 }} component="img" image={category.image}  alt={category.name}/>
            </Box>
            <Typography>Image</Typography>

            <TextField fullWidth value={image} onChange={function (event) {
                setImage(event.target.value)
            }}></TextField>
        </VStack>
        <HStack>
            <Typography>Enabled</Typography>
            <Switch checked={state.isEnabled} onChange={handleChange} name="isEnabled" >isEnabled</Switch>
            <Typography>Active</Typography>
            <Switch checked={state.isActive} onChange={handleChange} name="isActive" >isActive</Switch>
            <Typography>Final</Typography>
            <Switch checked={state.isFinal} onChange={handleChange} name="isFinal" >isFinal</Switch>
        </HStack>
        <Button variant={'contained'} onClick={saveCategory}>Save</Button>
    </SectionView>
}

export default DashboardCategory;
