import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React from "react";

function VStack(props) {
    var verticalAlign = props.vertical ?? "flex-start"
    var horizontal = props.horizontal ?? "flex-start"

    var children = React.Children.toArray(props.children);
    return <Grid container direction="row" justifyContent="flex-start" alignItems={verticalAlign} bgcolor={'#FFFFFF'} padding={props.padding} paddingLeft={props.paddingLeft} paddingTop={props.paddingTop} sx={props.sx}>
            {
                children.map(function (child) {
                    return <Grid key={child.key} item xs={child.props.xs ?? 12} md={child.props.md ?? 12} paddingBottom={props.spacing ?? '10px'}>
                        {
                            child
                        }
                    </Grid>
                })
            }
    </Grid>
}

export default VStack;