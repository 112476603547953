import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import VStack from "../../Layout/VStack";

function LinkView(props) {
    let { linkID } = useParams();

    useEffect(function () {
        console.log("Nice" + linkID)
    }, []);

    function redirect() {
        window.location = "/checkout/" + linkID
    }

    return <VStack>
        <Button variant="contained" onClick={redirect}>Redirect</Button>
    </VStack>
}

export default LinkView;