import React, {useEffect, useState} from 'react';
import Request from "../../../Network/Request";
import {useParams, useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import CheckoutProductCell from "./CheckoutProductCell";
import CheckoutBar from "../CheckoutBar";
import {usePersistedState} from "../../Imports";
import Grid from "@mui/material/Grid";
import Variation from "../Variation/Variation";
import {ButtonBase, LinearProgress} from "@mui/material";
import ReactGA from 'react-ga4';
import WorkplaceCell from "../../Workplaces/WorkplaceCell";
import VariationList from "../Variation/VariationList";
import SectionView from "../../Layout/SectionView";
import ImageView from "../../ImageView";
import HStack from "../../Layout/HStack";
import VStack from "../../Layout/VStack";
import TimeView from "../../TimeView";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

const style = {
    position: 'absolute',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    height : '100%',
    boxShadow: 24,
};

function CheckoutProductList(props) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)

    let { checkoutID } = useParams();
    let { linkID } = useParams();

    //List Worker Products
    const [workerProducts, setworkerProducts] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const [selectedWorkerProducts, setSelectedWorkerProducts] = useState([]);
    const [refresh, setRefresh] = useState("");

    const [selectedCategory, setSelectedCategory] = usePersistedState("selectedCategory", {});
    const [selectedWorkplace, setSelectedWorkplace] = usePersistedState("selectedWorkplace", {});
    const [selectedProduct, setSelectedProduct] = usePersistedState("selectedProduct", {});
    const [checkout, setCheckout] = useState(null);
    const [payments, setPayments] = useState([]);

    const [showBar, setShowBar] = useState(false);

    useEffect(function () {
        if (loaded == true) {
            return
        }

        ReactGA.event({
            category: 'User',
            action: 'Checkout'
        });

        loadPaymentTypes()

        var params = {
            categoryID : selectedCategory.categoryID,
            workplaceID : selectedWorkplace.workplaceID,
            productID : selectedProduct.productID
        }

        console.log("LinkID: " + linkID)

        if (linkID) {
            params.linkID = linkID
        }

        var checkoutRequest = new Request({
            name: "Checkout Products Start",
            path: "/api/checkout/start",
            params : params
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                setCheckout(response.checkout)
                setLoaded(true)
            }
            else
            {
                console.log(error)
                setTimeout(function () {
                    //navigate(-1)
                }, 3000)
            }
        }.bind(this));

    }, [loaded]);

    useEffect(function () {
        if (!checkout) {
            return
        }

        var checkoutRequest = new Request({
            name: "Checkout Product List",
            path: "/api/checkout/productslist",
            params : {
                checkoutID : checkout.checkoutID
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                setworkerProducts(response.workerProducts)
            }
        }.bind(this));

    }, [checkout]);

    useEffect(function () {
        setShowBar(selectedWorkerProducts.length > 0)

        if (selectedWorkerProducts.length == 0) {
            return;
        }

        var checkoutRequest = new Request({
            name: "Checkout Set Products",
            path: "/api/checkout/products",
            params : {
                checkoutID : checkout.checkoutID,
                productIDs : selectedWorkerProducts.map(function (workerProduct) {
                    return workerProduct.product.productID;
                })
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                setLoaded(true)
                setCheckout(response.checkout)
            }
        }.bind(this));

    }, [selectedWorkerProducts])

    function addProduct(product) {
        var set = new Set(selectedWorkerProducts)
        set.add(product)

        setSelectedWorkerProducts(Array.from(set))
    }

    function removeProduct(product) {
        var selected = selectedWorkerProducts.filter(function (prod) {
            return prod.workerProductID != product.workerProductID
        })

        setSelectedWorkerProducts(selected)
    }

    function contains(product) {
        var selected = selectedWorkerProducts.filter(function (prod) {
            return prod.workerProductID == product.workerProductID
        });

        return selected.length != 0
    }

    function handleClose() {

    }

    function loadPaymentTypes() {
        var paymentRequest = new Request({
            name: "List Payments",
            path: "/api/shop/payment/list"
        });

        paymentRequest.start(function (error, response) {
            if (!error) {
                setPayments(response.paymentTypes)
                console.log(response)
            }
        }.bind(this));
    }

    function selectPaymentType(paymentType) {
        var paymentRequest = new Request({
            name: "Checkout Set Payment",
            path: "/api/checkout/payment",
            params: {
                paymentTypeID: paymentType.paymentTypeID,
                checkoutID: checkout.checkoutID
            }
        });

        paymentRequest.start(function (error, response) {
            if (!error) {
                setCheckout(response.checkout)
                //props.checkoutChanged(response.checkout)
            }
        }.bind(this));
    }

    function showUserVariation() {

        return <VariationList></VariationList>

        if (checkout && checkout.variationUser) {
            var variationUser = checkout.variationUser
            return <Variation variation={variationUser.variation} onClick={function (event) {

            }}></Variation>
        }

        if (checkout && checkout.variation) {
            var variation = checkout.variation

            return <Variation variation={variation} onClick={function (event) {

            }}></Variation>
        }

        return  <label>No user variation</label>
    }

    if (!loaded) {
        return <LinearProgress />
    }

    function workplaceCell() {
        console.log("🚥 ")
        console.log(checkout)
        if (checkout && checkout.worker && checkout.worker.workplace) {
            return <WorkplaceCell workplace={ checkout.worker.workplace }></WorkplaceCell>
        }

        return <></>
    }

    return<>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} >
                <Button onClick={function () {
                    setOpen(false)
                }}>Close</Button>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Text in a modal
                </Typography>
                <TimeSelectorView></TimeSelectorView>

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                </Typography>
            </Box>
        </Modal>
        <Grid container direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            padding={'10px'}
            spacing={'10px'}>
            <Grid item md={12}>
                {
                    workplaceCell()
                }
            </Grid>

            <Grid container md={12} padding={'10px'}>
                <Grid item md={1}>
                    <Typography variant="h5" component="div" gutterBottom>
                        Variation
                    </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                    {
                        showUserVariation()
                    }
                </Grid>
            </Grid>
            <Grid container md={12} padding={'10px'}>
                <Grid item md={1}>

                </Grid>
            </Grid>
            <SectionView>
                <Typography align={'left'} variant="h5" component="div" gutterBottom padding={'10px'}>
                    Products
                </Typography>
            {
                workerProducts.map(function (workerProduct) {
                    return <CheckoutProductCell variant={'checkout'} selected={contains(workerProduct)} key={ workerProduct.workerProductID } contains={contains(workerProduct)} selectWorkerProduct={function (workerProduct) {
                            addProduct(workerProduct);
                        }} removeWorkerProduct={function (workerProduct) {
                            removeProduct(workerProduct)
                        }} workerProduct={ workerProduct }></CheckoutProductCell>
                })
            }
            </SectionView>
            <SectionView>
                <Typography align={'right'} variant="h5" component="div" gutterBottom padding={'10px'}>
                    Total : {checkout.price} RON
                </Typography>
            </SectionView>
            <SectionView>
                <Typography align={'left'} variant="h5" component="div" gutterBottom padding={'10px'}>
                    Payment
                </Typography>
                {
                    payments.map(function (payment) {
                        return <PaymentCell payment={payment}></PaymentCell>
                    })
                }
            </SectionView>

            <SectionView>
                <Typography align={'left'} variant="h5" component="div" gutterBottom padding={'10px'}>
                    Time
                </Typography>
                <HStack>
                    <ButtonBase onClick={function (event) {
                        setOpen(true)
                    }}>
                        <TimeView startDate={checkout.startDate} duration={checkout.duration}></TimeView>
                    </ButtonBase>
                </HStack>
            </SectionView>
            <CheckoutBar checkout={checkout}></CheckoutBar>
        </Grid>
    </>
}

function TimeSelectorView() {
    return <Paper style={{ maxHeight: '100%', overflow: 'auto'}}>
    </Paper>
}

function PaymentCell(props) {
    var payment = props.payment

    return <HStack>
        <ImageView height={70} md={2} lg={2} image={payment.image}></ImageView>
        <VStack>
            <Typography align={'left'} variant="subtitle1" component="div" gutterBottom>
                { payment.name }
            </Typography>
            <Typography align={'left'} variant="subtitle2" component="div" gutterBottom>
                { payment.details }
            </Typography>
        </VStack>
    </HStack>
}

export default CheckoutProductList;