import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import ImageView from "../../ImageView";
import Typography from "@mui/material/Typography";
import VStack from "../../Layout/VStack";
import HStack from "../../Layout/HStack";

function VariationUserCell(props) {
    var variationUser = props.variationUser

    if (!variationUser) {
        return <>Loading</>
    }

    function deleteButton() {
        if (props.onDelete) {
            return <Button md={6} xs={6} sm={6} variant={'outlined'} onClick={function () {
                props.onDelete(variationUser)
            }}>Delete</Button>
        }

        return null
    }

    function buttons() {
        if (props.onSelect) {
            return <VStack md={3} xs={6}>
                {
                    deleteButton()
                }
                <Button md={6} xs={6} sm={6} variant={'contained'} onClick={function () {
                    props.onSelect(variationUser)
                }}>Select</Button>
            </VStack>
        }

        return null
    }

    return <HStack padding={'10px'}>
        <ImageView md={1} xs={2} sm={2} lg={1} image={variationUser.variation.image}></ImageView>
        <VStack md={9} xs={4} sm={4} md={6} lg={8} paddingLeft={'10px'}>
            <Typography fontWeight={'bold'} align={'left'}>{variationUser.title}</Typography>
            <Typography variant={'body1'} align={'left'}>{variationUser.variation.name}</Typography>
        </VStack>
        {
            buttons()
        }
    </HStack>
}

export default VariationUserCell;