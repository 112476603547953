import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {AppBar, Button, Toolbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import ImageView from "../../../ImageView";
import SectionView from "../../../Layout/SectionView";
import VStack from "../../../Layout/VStack";
import Request from "../../../../Network/Request";
import WorkplaceCell from "../../../Workplaces/WorkplaceCell";
import VariationCell from "../../../Checkout/Variation/VariationCell";
import {usePersistedState} from "../../../Imports";
import SeobleView from "../../SeoView/SeobleView";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HStack from "../../../Layout/HStack";
import CenterStack from "../../../Layout/CenterStack";
function CheckoutButtonView(props) {
    let { categoryID } = useParams();

    let cart = props.cart

    const [workerProducts, setWorkerProducts] = useState(null)
    const [products, setProducts] = useState([])
//    const [cart, setCart] = usePersistedState('shoppingCart', props.cart)

    useEffect(function () {
        loadPage()
    }, []);

    function loadPage() {

        return
        var loadPage = new Request({
            name: "Product Page",
            path: "/api/shop/product/list",
            params: {
                categoryID : categoryID
            }
        });

        loadPage.start(function (error, response) {
            if (!error) {
                setProducts(response.products)
            }

        }.bind(this));
    }

    if (cart.products.length == 0) {
        return  <></>
    }

    return <>
        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
            <CenterStack>
                <HStack padding={'10px'}>
                    <VStack xs={8} md={4}>
                        <Typography variant={'body1'} align={'left'} fontWeight={'bold'} color={'black'}>
                            Price: { props.cart.totalPrice ? props.cart.totalPrice : 0 } £
                        </Typography>
                        <Typography variant={'body2'} align={'left'} color={'black'}>
                            Products: { props.cart.products.length  }
                        </Typography>
                    </VStack>
                    <Typography xs={0} md={4} variant="h6" component="div">
                    </Typography>
                    <Button fullWidth xs={4} md={4} variant={'contained'} onClick={ props.completed }>{ props.title ?? "Not set" }</Button>
                </HStack>
            </CenterStack>
        </AppBar>
    </>
}

export default CheckoutButtonView;