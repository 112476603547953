import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import ImageView from "../../ImageView";
import Typography from "@mui/material/Typography";
import VStack from "../../Layout/VStack";
import HStack from "../../Layout/HStack";
import SectionView from "../../Layout/SectionView";
import InfoIcon from '@mui/icons-material/Info';
function VariationCell(props) {
    var variation = props.variation

    function selectButton() {
        if (props.onSelect) {
            return <Button md={2} lg={1} variant={'contained'} md={2} xs={2} onClick={function () {
                props.onSelect(variation)
            }}>Select</Button>
        }

        return <VStack></VStack>
    }

    return <SectionView padding={'0px'}>
        <HStack padding={'10px'}>
            <ImageView md={1} xs={1} lg={1} image={ variation.image }></ImageView>
            <VStack md={8} xs={6} lg={8} padding={'10px'}>
                <Typography align={'left'} bgcolor={'#ffffff'}>{ variation.name }</Typography>
                <Typography align={'left'} bgcolor={'#ffffff'}>{ variation.details }</Typography>
            </VStack>
            <Button md={2} xs={2} lg={1} onClick={function () {

            }}><InfoIcon/></Button>
            {
                selectButton()
            }
        </HStack>
    </SectionView>
}

export default VariationCell;