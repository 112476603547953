import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CheckoutPayment from "../../Checkout/CheckoutPayment";
import ImageView from "../../ImageView";
import Paper from "@mui/material/Paper";
import SectionView from "../../Layout/SectionView";
import VStack from "../../Layout/VStack";
import HStack from "../../Layout/HStack";
import JSONView from "../../Layout/JSONView";

function LayoutTestView(props) {
    let { linkID } = useParams();

    useEffect(function () {
        console.log("Nice" + linkID)
    }, []);

    return <>
        <VStack>
            <SectionView>
                <VStack>
                    <Typography variant="h3">Layout Test View</Typography>
                </VStack>
            </SectionView>
            <SectionView>
                <HStack>
                    <VStack md={1}>
                        <Typography variant="h4">Workplace</Typography>
                        <Typography>This is details about checkout</Typography>
                    </VStack>
                    <VStack>
                        <Typography variant="h4">Workplace</Typography>
                        <Typography>This is details about checkout</Typography>
                    </VStack>
                </HStack>
            </SectionView>

            <SectionView>
                <VStack>
                    <Typography variant="h4">Products</Typography>
                    <Typography>This is details about checkout</Typography>
                </VStack>
            </SectionView>

            <SectionView>
                <VStack>
                    <Typography variant="h4">Payment</Typography>
                    <Typography>This is details about payment</Typography>
                </VStack>
            </SectionView>

            <SectionView>
                <VStack>
                    <Button variant={"contained"}>Buy Now</Button>
                </VStack>
            </SectionView>
        </VStack>
    </>
}

export default LayoutTestView;