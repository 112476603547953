import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import {useParams} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import WorkplaceList from "../Workplaces/WorkplaceList";
import Typography from "@mui/material/Typography";
import * as PropTypes from "prop-types";
import VStack from "../Layout/VStack";
import CenterStack from "../Layout/CenterStack";
import SectionView from "../Layout/SectionView";

const modals = {
    modal1 : <WorkplaceList/>
}

function DateTimePicker(props) {
    return null;
}

DateTimePicker.propTypes = {
    renderInput: PropTypes.func,
    label: PropTypes.string,
    onChange: PropTypes.func
};

function StaticDateTimePicker(props) {
    return null;
}

StaticDateTimePicker.propTypes = {
    renderInput: PropTypes.func,
    label: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any
};

function DatePicker(props) {
    return null;
}

DatePicker.propTypes = {
    showTimeSelect: PropTypes.bool,
    dateFormat: PropTypes.string,
    onChange: PropTypes.func,
    includeTimes: PropTypes.arrayOf(PropTypes.any),
    selected: PropTypes.any
};

function CheckoutBar(props) {
    let { categoryID } = useParams();
    const [category, setCategory] = useState(null);
    const [checkoutReservation, setcheckoutReservation] = useState(null);
    const [modalContent, setModalContent] = useState(0);
    const [startDate, setStartDate] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    let checkout = props.checkout

    useEffect(function () {

        console.log("🧾 Checkout")

        console.log(checkout)

    }, [checkout]);

    if (checkout) {
        return  <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" >
                <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
                    <CenterStack>
                        <VStack>
                            <SectionView>
                                <Typography align={'right'} variant="h5" component="div" gutterBottom padding={'10px'}>
                                    Total : {checkout.price} £
                                </Typography>
                            </SectionView>
                            <Button fullWidth style={{height : '50px'}} variant={'contained'} onClick={function () {
                                window.location = "/ordersummary/" + checkout.checkoutID
                            }}>Checkout</Button>
                        </VStack>
                    </CenterStack>
            </AppBar>
        </Grid>
    }
    else
    {
        return <>Loading</>
    }
}

export default CheckoutBar;