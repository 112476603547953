import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {AppBar, Button, Toolbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import ImageView from "../../../ImageView";
import SectionView from "../../../Layout/SectionView";
import VStack from "../../../Layout/VStack";
import Request from "../../../../Network/Request";
import WorkplaceCell from "../../../Workplaces/WorkplaceCell";
import VariationCell from "../../../Checkout/Variation/VariationCell";
import {usePersistedState} from "../../../Imports";
import SeobleView from "../../SeoView/SeobleView";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HStack from "../../../Layout/HStack";
import CenterStack from "../../../Layout/CenterStack";
import CheckoutButtonView from "../CheckoutButtonView/CheckoutButtonView";
function ShopCheckoutView(props) {
    let { categoryID } = useParams();

    const [workerProducts, setWorkerProducts] = useState(null)
    const [products, setProducts] = useState([])
    const [cart, setCart] = usePersistedState('shoppingCart', {})

    useEffect(function () {
        loadPage()
    }, []);

    function loadPage() {

        return
        var loadPage = new Request({
            name: "Product Page",
            path: "/api/shop/product/list",
            params: {
                categoryID : categoryID
            }
        });

        loadPage.start(function (error, response) {
            if (!error) {
                setProducts(response.products)
            }

        }.bind(this));
    }

    function goToOrderDetails() {
        window.location = '/check'
    }

    if (cart.products.length == 0) {
        return  <>
            <Typography>No products</Typography>
        </>
    }

    return <>
        <VStack>
            {
                cart.products.map(function (product) {
                    return <SectionView key={product.productID} >
                        <VStack padding={'5px'}>
                            <HStack padding={'5px'}>
                                <ImageView xs={4} md={3} lg={3} image={product.image} bgcolor={'#002211'} ></ImageView>
                                <VStack xs={3} md={7}>
                                    <Typography variant={'h5'} align={'left'}>{ product.title }</Typography>
                                    <Typography variant={'body1'}  align={'left'}>{ product.subtitle }</Typography>
                                    <Typography variant={'body2'} align={'left'}>{ product.details }</Typography>
                                    <Typography></Typography>
                                    <Typography></Typography>
                                    <Typography></Typography>
                                </VStack>
                                <VStack xs={5} md={2} >
                                    <Typography variant={'h5'} fontWeight={'bold'} align={'right'}>{ product.price } £</Typography>
                                    <Typography variant={'body1'} style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}} align={'right'}>{ product.discountedPrice } £</Typography>
                                    <HStack>
                                        <Typography xs={6} md={6}></Typography>
                                        {

                                        }
                                    </HStack>
                                </VStack>
                            </HStack>
                        </VStack>
                    </SectionView>
                })
            }
        </VStack>
        <CheckoutButtonView cart={cart} title={"Buy"} completed={function () {
            window.location = '/order'
        }}></CheckoutButtonView>
    </>
}

export default ShopCheckoutView;