import {Link} from "@mui/material";
import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function SiteMap(props) {
    const [loaded, setLoaded] = useState(false);

    useEffect(function () {
        if (loaded){
            return
        }
        /*
                var checkoutRequest = new Request({
                    name: "Workplaces",
                    path: "/api/checkout/workerproducts",
                    params : {
                        checkoutID : '3a37227a7bca4045d2b0'
                    }
                });

                checkoutRequest.start(function (error, response) {
                    if (!error) {
                        console.log(response)
                        setLoaded(true)
                    }
                }.bind(this));*/
    }, [loaded]);

    return <>
        <Paper>
            <Grid md={12} container direction="row" justifyContent="flex-end" alignItems="flex-start" padding={'10px'}>
                <Grid item md={12} bgcolor={'#ffffff'}>
                    <Box sx={{ width: '100%' }}>

                    </Box>
                </Grid>
                <Grid container md={6} direction="column" justifyContent="flex-start" alignItems="flex-start" padding={'10px'} bgcolor={'#ffffff'}>
                    <Grid item>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    </>
}

export default SiteMap;