import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {AppBar, Button, Toolbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import ImageView from "../../../ImageView";
import SectionView from "../../../Layout/SectionView";
import VStack from "../../../Layout/VStack";
import Request from "../../../../Network/Request";
import WorkplaceCell from "../../../Workplaces/WorkplaceCell";
import VariationCell from "../../../Checkout/Variation/VariationCell";
import {usePersistedState} from "../../../Imports";
import SeobleView from "../../SeoView/SeobleView";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HStack from "../../../Layout/HStack";
import CenterStack from "../../../Layout/CenterStack";
import CheckoutButtonView from "../CheckoutButtonView/CheckoutButtonView";
function ThankYouView(props) {
    let { categoryID } = useParams();

    const [workerProducts, setWorkerProducts] = useState(null)
    const [products, setProducts] = useState([])
    const [cart, setCart] = usePersistedState('shoppingCart', {})

    useEffect(function () {
        loadPage()
    }, []);

    function loadPage() {

        return
        var loadPage = new Request({
            name: "Product Page",
            path: "/api/shop/product/list",
            params: {
                categoryID : categoryID
            }
        });

        loadPage.start(function (error, response) {
            if (!error) {
                setProducts(response.products)
            }

        }.bind(this));
    }

    function goToOrderDetails() {
        window.location = '/check'
    }

    if (cart.products.length == 0) {
        return  <>
            <Typography>No products</Typography>
        </>
    }

    return <>
        <VStack>
            <Typography variant={'h5'} align={'left'}>Thank you for your order</Typography>
        </VStack>
    </>
}

export default ThankYouView;