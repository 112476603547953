import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Alert} from "@mui/material";

function ErrorView(props) {
    var error = props.error

    if (error) {
        return <Alert severity="error">{error.error_description}</Alert>
    }

    return <></>
}

export default ErrorView;