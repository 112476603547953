import React, {useEffect, useRef, useState, useMemo} from 'react';
import Request from "../../Network/Request";
import Box from "@mui/material/Box";
import {
    ListItemIcon,
    MenuItem,
    Switch,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ImageView from "../ImageView";
import MaterialReactTable from 'material-react-table';
import {AccountCircle, Send} from "@mui/icons-material";

function ProductDetail(props) {
    const [product, setProduct] = React.useState(props.product);
    const [state, setState] = React.useState({});

    const handleSave = () => {
        var saveProduct = new Request({
            name: "Save Product",
            path: "/api/admin/product/save",
            params : {
                productID : product.productID,
                options : state
            }
        });

        saveProduct.start(function (error, response) {
            if (!error && response.product) {
                setProduct(response.product)
            }
        }.bind(this));
    }

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.id]: event.target.value,
        });
    };

    useEffect(function () {
        //console.log(state)
    }, [state])

    return <Grid container spacing={1} paddingTop={'20px'} paddingLeft={'30px'}>
        <Grid container item md={4} spacing={3} bgcolor={'#a4a4a4'}>
            <ImageView height={'200px'} image={product.image}></ImageView>
        </Grid>
        <Grid container item md={6} spacing={3} bgcolor={'#a4a4a4'} paddingRight={'30px'}>
            <Grid container item md={12} spacing={3}>
                <TextField multiline maxRows={10} onChange={handleChange} fullWidth id="title" variant="outlined" defaultValue={product.title} helperText='Title'/>
            </Grid>
            <Grid container item md={12} spacing={3}>
                <TextField multiline onChange={handleChange} fullWidth id="details" variant="outlined" defaultValue={product.details} helperText='Details'/>
            </Grid>
            <Grid container item md={12} spacing={3}>
                <TextField multiline onChange={handleChange} fullWidth id="image" variant="outlined" defaultValue={product.image} helperText='Details'/>
            </Grid>
            <Grid container item md={12} spacing={3}>
                <Typography variant="subtitle1">Enabled</Typography>
                <Switch id="disabled" defaultValue={!(product.disabled ?? true)} onChange={handleChange}></Switch>
            </Grid>
            <Grid container item md={12} spacing={3}>
                <Button variant='contained' onClick={handleSave} >Save</Button>
            </Grid>
        </Grid>
        <Grid container spacing={3} md={2} direction="row" justifyContent="flex-start" alignItems="flex-start" bgcolor={'#a4a4a4'}>
            <Grid container item md={12} spacing={3}>
               <Button>Nice</Button>
            </Grid>
            <Grid container item md={12} spacing={3}>
                <Button>Nice</Button>
            </Grid>
            <Grid container item md={12} spacing={3}>
            </Grid>
        </Grid>
    </Grid>
}

function DashboardProducts(props) {
    const [payments, setpayments] = useState([]);
    const [actions, setActions] = useState([]);

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [categories, setCategories] = useState([]);

    const [data, setData] = useState([]);

    function loadProducts() {
        var loadProducts = new Request({
                name: "Dashboard Products",
                path: "/api/admin/product/list"
            });

        loadProducts.start(function (error, response) {
                if (!error && response.products) {
                    setData(response.products)
                }
        }.bind(this));
    }

    useEffect(function () {
        loadProducts()
    }, [])

    const columns = useMemo(
        () => [
            {
                accessorKey: 'category.name', //access nested data with dot notation
                header: 'Category',
                Cell: function ({cell, row}) {
                    var category = row.original.category;
                    return <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <ImageView height={'100px'} image={category.image}></ImageView>
                        <Typography>{category.name}</Typography>
                    </Box>
                }
            },
            {
                header: 'Image',
                Cell: function ({cell, row}) {
                    var product = row.original;
                    return <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <ImageView height={'100px'} image={product.image}></ImageView>
                    </Box>
                }
            },
            {
                accessorKey: 'title', //access nested data with dot notation
                header: 'Product',
                Cell: function ({cell, row}) {
                    var product = row.original;
                    return <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <Typography>{product.title}</Typography>
                    </Box>
                }
            }
        ],
        [],
    );

    document.title = "Dashboard - Products"

    return <>
        <Grid container alignItems="stretch" bgcolor={'#ffffff'}>
            <Grid item md={12}>
                 <MaterialReactTable
                     columns={columns}
                     data={data}
                     enableGrouping

                     renderRowActionMenuItems={({ closeMenu }) => [
                         <MenuItem
                             key={0}
                             onClick={() => {
                                 // View profile logic...
                                 closeMenu();
                             }}
                             sx={{ m: 0 }}
                         >
                             <ListItemIcon>
                                 <AccountCircle />
                             </ListItemIcon>
                             View Profile
                         </MenuItem>,
                         <MenuItem
                             key={1}
                             onClick={() => {
                                 // Send email logic...
                                 closeMenu();
                             }}
                             sx={{ m: 0 }}
                         >
                             <ListItemIcon>
                                 <Send />
                             </ListItemIcon>
                             Send Email
                         </MenuItem>,
                     ]}
                     renderDetailPanel={({ row }) => (
                         <ProductDetail product={row.original}></ProductDetail>
                     )}
                 />;
            </Grid>
        </Grid>
    </>
}

export default DashboardProducts;
