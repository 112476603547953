import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {AppBar, Button, ButtonBase, TextareaAutosize, TextField, Toolbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import ImageView from "../../../ImageView";
import SectionView from "../../../Layout/SectionView";
import VStack from "../../../Layout/VStack";
import Request from "../../../../Network/Request";
import WorkplaceCell from "../../../Workplaces/WorkplaceCell";
import VariationCell from "../../../Checkout/Variation/VariationCell";
import {usePersistedState} from "../../../Imports";
import SeobleView from "../../SeoView/SeobleView";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HStack from "../../../Layout/HStack";
import CenterStack from "../../../Layout/CenterStack";
function ShopOrderDetailsView(props) {
    let { categoryID } = useParams();

    const [workerProducts, setWorkerProducts] = useState(null)
    const [products, setProducts] = useState([])
    const [cart, setCart] = usePersistedState('shoppingCart', {})
    const [selectedPayment, setSelectedPayment] = usePersistedState('selectedPayment', null)
    const [payment, setPayment] = usePersistedState('paymentMethod', {
        paymentMethods : [
            {
                methodID : "3",
                title : "Card",
                image : ""
            },
            {
                methodID : "2",
                title : "Cash",
                image : ""
            }
        ]
    })

    useEffect(function () {
        loadPage()
        if (!selectedPayment) {
            setSelectedPayment(payment.paymentMethods.pop())
        }

    }, []);

    function loadPage() {

        return
        var loadPage = new Request({
            name: "Product Page",
            path: "/api/shop/product/list",
            params: {
                categoryID : categoryID
            }
        });

        loadPage.start(function (error, response) {
            if (!error) {
                setProducts(response.products)
            }

        }.bind(this));
    }

    if (cart.products.length == 0) {
        return  <></>
    }

    return <>
        <VStack md={12} padding={'10px'}>
            <Typography align={'left'}>Order details:</Typography>
            <VStack>
                <TextField fullWidth placeholder={'Name'}></TextField>
                <TextField fullWidth placeholder={'Room Number'}></TextField>
                <TextField fullWidth placeholder={'Anything else to add?'}></TextField>
            </VStack>
            <Typography align={'left'}>Payment:</Typography>
            <VStack>
                {
                    payment.paymentMethods.map(function (method) {
                        return <SectionView key={ method.methodID } selected={ selectedPayment == null ? false : (method.methodID == selectedPayment.methodID) }>

                            <ButtonBase onClick={function () {
                                setSelectedPayment(method)
                            }}>
                                <VStack>
                                    <Typography>{ method.title }</Typography>
                                </VStack>
                            </ButtonBase>

                        </SectionView>
                    })
                }
            </VStack>
            <Typography align={'left'}>Order summary:</Typography>
            <SectionView>
                <HStack padding={'10px'}>
                    <VStack md={6}>
                        <Typography align={'left'}>Location: Hotel Nice</Typography>
                        <Typography align={'left'}>Estimated Time: 10 Minutes</Typography>
                    </VStack>
                    <VStack md={6}>
                        <Typography align={'right'}>Price: { cart.totalPrice } £</Typography>
                    </VStack>
                </HStack>
            </SectionView>
        </VStack>
        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
            <CenterStack>
                <VStack padding={'10px'}>
                    <Button fullWidth md={12} variant={'contained'} onClick={function () {
                        window.location = '/thankyou'
                    }}>
                        <Typography padding={'10px'}>
                            Complete Order
                        </Typography>
                    </Button>
                </VStack>
            </CenterStack>
        </AppBar>
    </>
}

export default ShopOrderDetailsView;