import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Grid from "@mui/material/Grid";
import {useParams} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import WorkplaceList from "../../Workplaces/WorkplaceList";
import VStack from "../../Layout/VStack";
import SectionView from "../../Layout/SectionView";
import WorkplaceCell from "../../Workplaces/WorkplaceCell";
import CheckoutProducts from "../../Checkout/CheckoutProducts";
import Variation from "../../Checkout/Variation/Variation";
import TimeView from "../../TimeView";
import CheckoutPayment from "../../Checkout/CheckoutPayment";
import CenterStack from "../../Layout/CenterStack";
import Request from "../../../Network/Request";
import VariationUserCell from "../../Checkout/Variation/VariationUserCell";
import DateView from "../ReservationsView/DateView";
import HStack from "../../Layout/HStack";
import PaymentCell from "../Cells/PaymentCell";

const modals = {
    modal1 : <WorkplaceList/>
}

function Checkout(props) {
    let { checkoutID } = useParams();
    const [checkout, setCheckout] = useState(null);
    const [category, setCategory] = useState(null);
    const [checkoutReservation, setcheckoutReservation] = useState(null);
    const [modalContent, setModalContent] = useState(0);
    const handleClose = () => setModalContent(0);

    useEffect(function () {
        if (!checkoutID) {
            return
        }

        var checkoutRequest = new Request({
            name: "Checkout",
            path: "/api/checkout/start",
            params : {
                checkoutID : checkoutID
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                setCheckout(response.checkout)

                console.log(response)
            }
        }.bind(this));

    }, [category]);

    useEffect(function () {
        if (!checkoutReservation) {
            return
        }

        var checkoutRequest = new Request({
            name: "Make Reservation",
            path: "/api/reservation/new",
            params : {
                checkoutID : checkoutReservation.checkoutID
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                var reservationID = response.reservation.reservationID;

                window.location = "/reservation/" + reservationID

                console.log(response)
            }
        }.bind(this));

    }, [checkoutReservation]);

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(5),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    if (checkout) {
        return <VStack>
            <SectionView>
                <WorkplaceCell workplace={checkout.worker.workplace}></WorkplaceCell>
            </SectionView>
            <SectionView>
                <VariationUserCell variationUser={checkout.variationUser}></VariationUserCell>
            </SectionView>
            <SectionView>
                <CheckoutProducts variant={"ordersummary"} checkout={checkout}></CheckoutProducts>
            </SectionView>
            <SectionView>
                <VStack padding={'10px'}>
                    <DateView md={4} date={checkout.startDate}></DateView>
                    <TimeView md={2} startDate={checkout.startDate} duration={checkout.duration}></TimeView>
                    <TimeView md={2} startDate={checkout.endDate} duration={checkout.duration}></TimeView>
                </VStack>
            </SectionView>
            <SectionView>
                <PaymentCell payment={checkout.paymentType}></PaymentCell>
            </SectionView>
            <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
                <CenterStack>
                    <VStack padding={'10px'}>
                        <Button fullWidth variant={'contained'} onClick={function () {
                            setcheckoutReservation(checkout)
                        }}>Buy now</Button>
                    </VStack>
                </CenterStack>
            </AppBar>
        </VStack>
    }
    else
    {
        return <>Loading</>
    }
}

export default Checkout;
