import {Link} from "@mui/material";
import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VStack from "../Layout/VStack";
import SectionView from "../Layout/SectionView";

var faqs = [
    {
        "question": "How do I book a service?",
        "answer": "To book a service, simply select the service you need, choose your preferred date and time, and then enter your payment information."
    },
    {
        "question": "Can I schedule a service for a specific date and time?",
        "answer": "Yes, you can schedule a service for a specific date and time through our online booking system."
    },
    {
        "question": "How can I view the service provider's availability?",
        "answer": "You can view the service provider's availability by visiting their profile or by selecting a service and choosing a date and time."
    },
    {
        "question": "How do I pay for the service?",
        "answer": "Payment can be made through our secure online payment system using a credit or debit card."
    },
    {
        "question": "Can I cancel or reschedule a booking?",
        "answer": "Yes, you can cancel or reschedule a booking through your account or by contacting customer support. Please refer to our cancellation policy for more information."
    },
    {
        "question": "Are there any promotions or discounts available?",
        "answer": "Yes, we regularly offer promotions and discounts to our customers. You can view the current promotions by visiting our website or app."
    },
    {
        "question": "What is the process for requesting a specific service provider?",
        "answer": "You can request a specific service provider by visiting their profile and selecting 'Request this Provider' or by contacting customer support."
    },
    {
        "question": "What happens if the service provider is running late?",
        "answer": "If a service provider is running late, we will contact you as soon as possible to inform you of the delay and provide you with an estimated arrival time."
    },
    {
        "question": "Can I leave a review for the service provider?",
        "answer": "Yes, you can leave a review for the service provider after the service is completed."
    },
    {
        "question": "What is the refund policy for cancellations?",
        "answer": "Our refund policy varies depending on the service booked and the notice given for the cancellation. Please refer to our cancellation policy for more information."
    },
    {
        "question": "How do I contact customer support?",
        "answer": "You can contact customer support through the app, by email or by phone."
    },
    {
        "question": "How can I update my account information?",
        "answer": "You can update your account information by visiting your account settings within the app."
    },
    {
        "question": "Can I request additional services during my booking?",
        "answer": "Yes, you can request additional services during your booking by contacting the service provider or customer support."
    },
    {
        "question": "What types of services does the app offer?",
        "answer": "Our app offers a wide range of services, please refer to our website or app for more information."
    },
    {
        "question": "How do I know if the service provider is licensed and insured?",
        "answer": "All of our service providers are licensed and insured. You can view their credentials on their profile."
    },
]


    function FreqAskedQuestions(props) {
    const [loaded, setLoaded] = useState(false);

    useEffect(function () {
        if (loaded){
            return
        }
        /*
                var checkoutRequest = new Request({
                    name: "Workplaces",
                    path: "/api/checkout/workerproducts",
                    params : {
                        checkoutID : '3a37227a7bca4045d2b0'
                    }
                });

                checkoutRequest.start(function (error, response) {
                    if (!error) {
                        console.log(response)
                        setLoaded(true)
                    }
                }.bind(this));*/
    }, [loaded]);

    return <SectionView>
        <VStack padding={'10px'}>
            <Typography align={"left"} paddingLeft={'12px'} fontWeight={'bold'}>Frequently asked questions</Typography>
            <VStack>
                {
                    faqs.map(function (faq){
                        return <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <Typography align={"left"} fontWeight={'bold'} >{faq.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography align={"left"}>
                                    {faq.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    })
                }
            </VStack>
        </VStack>
    </SectionView>
}

export default FreqAskedQuestions;