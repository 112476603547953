import {Link} from "@mui/material";
import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function BecomePartner(props) {
    const [loaded, setLoaded] = useState(false);

    useEffect(function () {
        if (loaded){
            return
        }
        /*
                var checkoutRequest = new Request({
                    name: "Workplaces",
                    path: "/api/checkout/workerproducts",
                    params : {
                        checkoutID : '3a37227a7bca4045d2b0'
                    }
                });

                checkoutRequest.start(function (error, response) {
                    if (!error) {
                        console.log(response)
                        setLoaded(true)
                    }
                }.bind(this));*/
    }, [loaded]);

    return <>
        <Paper>
            <Grid md={12} container direction="row" justifyContent="flex-end" alignItems="flex-start" padding={'10px'}>
                <Grid item md={12} bgcolor={'#ffffff'}>
                    <Box sx={{ width: '100%' }} align={'left'}>
                        <Typography variant="h5" gutterBottom align={'left'}>
                            Welcome to the LiteApp Partner Program!
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            We are excited to offer individuals and businesses the opportunity to join our platform as service providers and offer their services to customers through our website, iOS app, and Android app.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Joining our partner program is easy and free.
                        </Typography>
                        <Typography>
                            To become a partner, please follow these steps:
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Create an account on our website by registering your personal or business information.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Fill out the service provider application form. This will include information about your business, the services you offer, and your availability.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Provide us with all the necessary documents such as business registration, insurance and any other relevant certifications.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Our team will review your application and contact you with any additional information or questions we may have.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Once your application is approved, you will be able to create a profile on our platform and start listing your services for customers to book.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            By becoming a partner with LiteApp, you will have access to a large customer base, a user-friendly platform, and flexible scheduling options. You will also have the ability to manage your bookings, view customer reviews, and receive payments directly through our platform.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We strive to provide our partners with the best possible experience, and are always available to assist with any questions or concerns. If you have any questions about the partner program, please contact our customer support team.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Sign up now and start providing services with LiteApp!
                        </Typography>
                    </Box>
                </Grid>
                <Grid container md={6} direction="column" justifyContent="flex-start" alignItems="flex-start" padding={'10px'} bgcolor={'#ffffff'}>
                    <Grid item>
                        <Button variant={'contained'} href={'/login'}>Sign up</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    </>
}

export default BecomePartner;