import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

function SectionView(props) {
    var selected = props.selected

    function borderColor() {
        if (props.selected) {
            return 'primary.main'
        }

        return '#ffffff'
    }

    var sx= Object.assign({ border: 4, borderColor: borderColor()}, props.sx ?? {})

    return <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" bgcolor={'#FFFFFF'} padding={props.padding} paddingTop={props.paddingTop} paddingBottom={props.paddingBottom} sx={props.sx ?? sx}>
            <Grid item xs={12} md={12}>
                <Paper elevation={2}>
                    {
                        props.children
                    }
                </Paper>
            </Grid>
    </Grid>
}

export default SectionView;