import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, TextField, Toolbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CheckoutPayment from "../../Checkout/CheckoutPayment";
import ImageView from "../../ImageView";
import Paper from "@mui/material/Paper";
import SectionView from "../../Layout/SectionView";
import VStack from "../../Layout/VStack";
import HStack from "../../Layout/HStack";
import JSONView from "../../Layout/JSONView";
import Request from "../../../Network/Request";
import {usePersistedState} from "../../Imports";
import VariationCell from "../../Checkout/Variation/VariationCell";
import VariationUserCell from "../../Checkout/Variation/VariationUserCell";
import AppBar from "@mui/material/AppBar";
import TranslationView from "../CustomViews/TranslationView";

function VariationsView(props) {
    let { linkID } = useParams();

    const [selectedCategory, setSelectedCategory] = usePersistedState("selectedCategory", {});
    const [selectedUserVariation, setSelectedUserVariation] = usePersistedState("selectedUserVariation", {});

    const [searchString, setSearchString] = useState(null);

    const [loadVariations, setLoadVariations] = useState(false);
    const [variations, setVariations] = useState([]);
    const [selectedVariation, setSelectedVariation] = usePersistedState("selectedVariation", null);

    const [viewState, setViewState] = useState(0); // 0 - Variation List , 1 - Add Variation details, 2 - View user variations

    const [plateNR, setPlateNR] = useState(null)

    function categoryID() {
        return selectedCategory.categoryID
    }

    useEffect(function () {
        if (selectedVariation) {
         //   setViewState(1)
        }

    }, [])

    useEffect(function () {
        var categoryList = new Request({
            name: "Variation Category List",
            path: "/api/variations/category",
            params : {
                categoryID : categoryID()
            }
        });

        categoryList.start(function (error, response) {
            if (!error) {
                setVariations(response.variations)
                setLoadVariations(false)
            }
        }.bind(this));
    }, [loadVariations]);

    function addVariation() {
        var variationAdd = new Request({
            name: "Variation New",
            path: "/api/variations/new",
            params : {
                title : plateNR,
                categoryID : categoryID(),
                variationID : selectedVariation.variationID
            }
        });

        variationAdd.start(function (error, response) {
            if (!error) {
                setSelectedVariation(null)
                setSelectedUserVariation(response.variationUser)
                window.location = "/checkout"
                //window.location = "/variations"
            }
        }.bind(this));
    }

    function selectVariation(variation) {
        setSelectedVariation(variation)
        setViewState(1)
    }

    function next() {
        var location = '/checkout'
        if (linkID) {
            location = location + "/" + linkID
        }

        window.location = location
    }

    function handleTextField(event) {
        setPlateNR(event.target.value)
    }

    function filteredVariations() {
        if (searchString) {
            return variations.filter(function (variation) {
                var details = variation.details
                var name = variation.name

                if (name && name.toLowerCase().includes(searchString)) {
                    return true
                }

                if (details && details.toLowerCase().includes(searchString)) {
                    return true
                }

                return false
            })
        }

        return variations
    }

    function viewStateView() {
        if (viewState == 0) {
            return <VStack padding={'10px'}>
                <TextField fullWidth label={'Search'} onChange={function (event) {
                    console.log(event.target.value)
                    setSearchString(event.target.value.toLowerCase())
                }}></TextField>
                {
                    filteredVariations().map(function (variation) {
                        return  <VariationCell key={ variation.variationID } onSelect={ selectVariation } variation={ variation }></VariationCell>
                    })
                }
            </VStack>
        }
        else if (viewState == 1 && selectedVariation) {
            return <SectionView>
                <VStack padding={'10px'}>
                    <VariationCell variation={selectedVariation}></VariationCell>
                    <TextField onChange={handleTextField} fullWidth label={
                        <TranslationView>{
                            selectedCategory.variationName + ":title"
                        }
                        </TranslationView>}>
                    </TextField>
                    <Button fullWidth variant={'contained'} onClick={function (params) {
                        addVariation()
                    }}>
                        <TranslationView>
                            Save
                        </TranslationView>
                    </Button>
                    <Button fullWidth variant={'outlined'} onClick={function (params) {
                        setSelectedVariation(null)
                        window.location = '/variations'
                    }}><TranslationView>
                        Cancel
                    </TranslationView>
                    </Button>
                </VStack>
            </SectionView>
        }
        else {
            return <>Loading</>
        }
    }
    
    return <VStack paddingTop={'10px'}>
        {
            viewStateView()
        }
    </VStack>
}

export default VariationsView;