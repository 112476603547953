import {usePersistedState} from "../../Imports";

function TranslationView(props) {
    const [translations, setTranslations] = usePersistedState('translations', {});

    var textToTranslate = props.children
    var translatedText = translations[textToTranslate] ?? textToTranslate

    return translatedText
}

export default TranslationView