import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import {CardMedia} from "@mui/material";
import Typography from "@mui/material/Typography";

function CheckoutPlaceCell(props) {
    const [loaded, setLoaded] = useState(false);

    useEffect(function () {
        return;
/*
        var checkoutRequest = new Request({
            name: "Workplaces",
            path: "/api/checkout/findworkplaces",
            params : {
                checkoutID : '3a37227a7bca4045d2b0'
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                console.log(response)
                setLoaded(true)
            }
        }.bind(this));*/

    }, [loaded]);

    function getAddress() {
        var city = props.checkoutPlace.workplace.city;
        var county = props.checkoutPlace.workplace.city;

        if (city == county ) {
            return props.checkoutPlace.workplace.street + ", " + props.checkoutPlace.workplace.city;
        }

        return props.checkoutPlace.workplace.street + ", " + props.checkoutPlace.workplace.city + ", " + props.checkoutPlace.workplace.county;
    }

    return <>
        <Paper elevation={10} sx={{ borderRadius: 2 }} padding={'10px'}>
            <Grid md={12} container direction="row" justifyContent="flex-end" alignItems="flex-start" >
                <Grid item height={'300px'} md={7} bgcolor={'#FFFFFF'}>
                    <CardMedia sx={{ borderRadius: 2 }} component="img"  height="100%"  image={ props.checkoutPlace.workplace.images[0] }  alt={props.checkoutPlace.workplace.name}/>
                </Grid>
                <Grid  sx={{ borderRadius: 2 }} container md={5} direction="column" justifyContent="space-evenly" alignItems="stretch" padding={'20px'} bgcolor={'#FFFFFF'}>
                    <Grid item>
                        <Typography variant="h6" component="h4">
                            {props.checkoutPlace.workplace.name}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1" component="h4" >
                             { getAddress() }
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1" component="h4" >
                            {
                                props.checkoutPlace.workplace.ratingTotal.ratingCount ? <label>Rating: { props.checkoutPlace.workplace.ratingTotal.rating + "⭐️  (" + props.checkoutPlace.workplace.ratingTotal.ratingCount + " ratings)" }</label> : <label>No rating</label>
                            }
                        </Typography>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="stretch">
                        <Grid item >
                            <Typography variant="body1" component="h2" paddingTop={'40px'}>
                                Price: {props.checkoutPlace.worker.reservationContext.price} £
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item paddingBottom={'20px'}>
                        <Typography variant="body1" component="h4" >
                            Time: { new Date(props.checkoutPlace.worker.reservationContext.intervals[0].startDate).toLocaleTimeString([],{timeStyle: 'short'}) } Date: { new Date(props.checkoutPlace.worker.reservationContext.intervals[0].startDate).toLocaleDateString([],{day: 'numeric', month:'long', year : 'numeric'}) }
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant={'contained'} onClick={function () {
                            props.selectedCheckoutplace(props.checkoutPlace)
                        }}>Alege</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    </>
}

export default CheckoutPlaceCell;
