import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogContent, DialogTitle} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CheckoutPayment from "../../Checkout/CheckoutPayment";
import ImageView from "../../ImageView";
import Paper from "@mui/material/Paper";
import SectionView from "../../Layout/SectionView";
import VStack from "../../Layout/VStack";
import HStack from "../../Layout/HStack";
import JSONView from "../../Layout/JSONView";
import Request from "../../../Network/Request";
import WorkplaceCell from "../../Workplaces/WorkplaceCell";
import Variation from "../../Checkout/Variation/Variation";
import Stack from "@mui/material/Stack";
import ActionCell from "../../Reservation/ActionCell";

function ReservationView(props) {
    var { reservationID } = useParams();

    const [loaded, setLoaded] = useState(false);
    const [reservation, setReservation] = useState(null);
    const [actions, setActions] = useState([]);
    const [action, setAction] = useState(null);

    useEffect(function () {
        if (loaded){
            return
        }

        var checkoutRequest = new Request({
            name: "Reservation Details",
            path: "/api/reservation/details",
            params : {
                reservationID : reservationID
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                console.log(response)
                setReservation(response.reservation)
                setLoaded(true)

                setTimeout(function () {
                    setLoaded(false)
                }, 3000)
            }
        }.bind(this));
    }, [loaded]);

    useEffect(function () {
        var checkoutRequest = new Request({
            name: "Reservation Details",
            path: "/api/reservation/actionlist",
            params : {
                reservationID : reservationID
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                console.log(response)
                setActions(response.actions)
                //setLoaded(true)
            }
        }.bind(this));
    }, [reservation]);

    function processAction(action) {
        var checkoutRequest = new Request({
            name: "Reservation Details",
            path: "/api/reservation/actionset",
            params : {
                reservationID : reservationID,
                actionID : action.actionID
            }
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                var resolution = response.resolution;

                if (resolution.type == 'payment') {
                    window.location = resolution.payment.paymentURL
                }
                else
                {
                    setLoaded(false)
                }
            }
        }.bind(this));
    }

    function handleClose() {

    }

    function open() {

    }

    function dialog() {
        if (action) {
            return <Dialog onClose={handleClose} open={action!=null}>
                <SectionView>
                    <VStack padding={'10px'}>
                        <DialogTitle>{action.title}</DialogTitle>
                        <DialogContent>{action.details}</DialogContent>
                        <VStack>
                            <Button fullWidth variant={'contained'} onClick={ function () {
                                processAction(action)
                                setAction(null)
                            }}>{action.title}</Button>
                            <Button fullWidth variant={'outlined'}  onClick={ function () {
                                setAction(null)
                            }}>Dismiss</Button>
                        </VStack>
                    </VStack>
                </SectionView>
            </Dialog>
        }

        return <></>
    }

    if (reservation)
    {
        return <SectionView>
            <VStack padding={'10px'}>
                <Typography align={'left'} variant="h5" component="div" gutterBottom>
                    { reservation.status }
                </Typography>
                <WorkplaceCell workplace={ reservation.worker.workplace }></WorkplaceCell>
                {
                    actions.map(function (action) {
                        return <ActionCell key={action.actionID} action={action} color={action.color} onActionSelected={function (action) {
                            if (action.alert) {
                                setAction(action)
                            }
                            else
                            {
                                processAction(action)
                            }
                        }}></ActionCell>
                    })
                }
            </VStack>
            {
                dialog()
            }
        </SectionView>
    }
    else
    {
        return <label>Loading</label>
    }
}

export default ReservationView;