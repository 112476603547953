import React, {useEffect, useRef, useState} from 'react';
import Request from "../../Network/Request";
import Box from "@mui/material/Box";
import {
    CardMedia,
    List,
    ListItem,
    Switch,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { useSearchParams } from "react-router-dom";
import DashboardVariations from "./DashboardVariations";
import DashboardProducts from "./DashboardProducts";
import DashboardCategory from "./DashboardCategory";
import VStack from "../Layout/VStack";

function CategoryDashboardCell(props) {
    var category = props.category

    const [state, setState] = React.useState({
        isEnabled: category.isEnabled,
        isActive: category.isActive
    });

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    return <Grid item md={12} lg={12} xl={12} bgcolor={'#ffffff'}>
        <Paper elevation={2}>
            <Grid container md={12} lg={12} xl={12} bgcolor={'#ffffff'}>
                <Grid item md={4} bgcolor={'#ffffff'}>
                    <Typography>{category.name}</Typography>
                    <Box padding={'3px'}>
                        <CardMedia key={category.categoryID} sx={{ borderRadius: 2 }} component="img" image={category.image}  alt={category.name}/>
                    </Box>
                </Grid>
                <Grid item md={8} lg={8} xl={8} bgcolor={'#ffffff'}>
                    Enabled
                    <Switch checked={state.isEnabled} onChange={handleChange} name="isEnabled" />
                    Active
                    <Switch checked={state.isActive} onChange={handleChange} name="isActive" />
                    <Button variant={'contained'} onClick={function () {
                        window.location = "/dashboard/category/" + category.categoryID
                    }}>Details</Button>
                </Grid>
            </Grid>
        </Paper>
    </Grid>
}

function DashboardCategories(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [payments, setpayments] = useState([]);
    const [actions, setActions] = useState([]);

    const [state, setState] = useState({})
    const [categories, setCategories] = useState([]);

    const [error, setError] = useState(null);

    function loadCategories() {
            var loadCategories = new Request({
                name: "Workplaces",
                path: "/api/admin/categories/list"
            });

        loadCategories.start(function (error, response) {
                if (!error && response.categories) {
                    setCategories(response.categories)
                }
        }.bind(this));
    }

    function createNew() {
        var newCAtegory = new Request({
            name: "New Category",
            path: "/api/admin/categories/new"
        });

        newCAtegory.start(function (error, response) {
            if (!error && response.category) {
                window.location = "/dashboard/category/" + response.category.categoryID
            }
        }.bind(this));
    }

    useEffect(function () {
        loadCategories()
    }, [])

    useEffect(function () {
        setSearchParams(state)
    }, [state])

    function handle(e) {
        console.log(e.target)
    }

    const updateState = (object) => {
        var key = Object.keys(object).pop()
        var value = object[key]

        setState((prevState) => {
            const newState = { ...prevState, [key]: value };
            return newState;
        });
    };

    function content() {
        var selectedOption = state.selectedOption;// ["selectedOption"]
        switch (selectedOption) {
            case 'Variations':
                return <DashboardVariations></DashboardVariations>
                break;
            case 'Products':
                return <DashboardProducts></DashboardProducts>
                break;
            case 'Settings':
                return <DashboardCategory></DashboardCategory>
                return 'Settings'
                break;
            default:
                return <>Select option</>
        }
    }

    return <VStack>
        <Button onClick={function () {
            createNew()
        }}>Create new category</Button>
        {categories.map((category) => (
           <ListItem key={category.categoryID}  disableGutters >
               <CategoryDashboardCell category={category}></CategoryDashboardCell>

           </ListItem>
        ))}
    </VStack>
}

export default DashboardCategories;
