import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import SectionView from "../../Layout/SectionView";
import VStack from "../../Layout/VStack";
import HStack from "../../Layout/HStack";
import Grid from "@mui/material/Grid";
import ScreenCenter from "../../Layout/ScreenCenter";

function LoginView(props) {
    let { linkID } = useParams();

    useEffect(function () {
        console.log("Nice" + linkID)
    }, []);

    function loginSMS() {
        window.location = '/loginsms'
    }

    function loginEmail() {
        window.location = '/loginemail'
    }

    return   <SectionView>
        <VStack padding={'10px'}>
            <Typography align={'left'} fontWeight={'bold'} variant={'h5'}>Account Authentication</Typography>
            <Typography align={'left'}>Welcome to LiteApp account authentication page!</Typography>
            <Typography align={'left'}>We offer multiple secure options for verifying your identity and accessing your account. Choose the method that suits you best.</Typography>
        </VStack>
        <VStack padding={'10px'} >
            <Typography fontWeight={'bold'} variant={'h6'}>Authenticate with:</Typography>
            <Button fullWidth variant="contained" onClick={loginSMS}>SMS</Button>
            <Button fullWidth variant="contained" onClick={loginEmail}>Email</Button>
        </VStack>
    </SectionView>
}

export default LoginView;