import React from 'react';
import Request from '../../Network/Request'
import ReservationsWorker from "./ReservationsWorker";

class ReservationBoard extends React.Component
{
    constructor(props)
    {
        super(props);
        this.workers = [];
    }

    componentDidMount()
    {
        this.loadWorkers();
    }

    loadWorkers()
    {
        var request = new Request({
            name : "Worker List",
            path : "/api/worker/list"
        });

        request.start(function (error, response) {
            if (!error)
            {
                this.workers = response.workers;
                this.forceUpdate();
            }
        }.bind(this));
    }

    render()
    {
        return (
            <div style={{ display:'flex', flexDirection : 'row', padding: 5 }}>
                {
                    this.workers.map(function (worker) {
                        return <ReservationsWorker style={{ flex : 1 }} worker={ worker } key={ worker.workerID }></ReservationsWorker>
                    })
                }
            </div>
        );
    }
}

export default ReservationBoard;
