import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CheckoutPayment from "../../Checkout/CheckoutPayment";
import ImageView from "../../ImageView";
import Paper from "@mui/material/Paper";
import SectionView from "../../Layout/SectionView";
import VStack from "../../Layout/VStack";
import HStack from "../../Layout/HStack";
import JSONView from "../../Layout/JSONView";
import Request from "../../../Network/Request";
import ReservationCell from "../../Reservation/ReservationCell";
import {usePersistedState} from "../../Imports";

function ReservationsView(props) {
    let { linkID } = useParams();
    const [loaded, setLoaded] = useState(false)
    const [reservations, setReservations] = useState([])

    useEffect(function () {
        if (loaded) {
            return
        }

        var checkoutRequest = new Request({
            name: "Checkout Product List",
            path: "/api/reservation/list"
        });

        checkoutRequest.start(function (error, response) {
            if (!error) {
                setReservations(response.reservations)

                ///setListingWorkerProducts(workerProducts)
            }
        }.bind(this));
    }, [loaded]);

    function onReservationDetails() {
        window.location = "/reservation/asdasdas"
    }

    return <VStack>
            <Typography padding={'10px'} align={'left'} variant={'h5'}>My Reservations</Typography>
            {
                reservations.map(function (reservation) {
                    return <SectionView key={reservation.reservationID}>
                        <HStack>
                            <ReservationCell reservation={reservation}>

                            </ReservationCell>
                        </HStack>
                    </SectionView>
                })
            }
    </VStack>
}

export default ReservationsView;