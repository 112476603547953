import React from 'react';

function usePersistedState(key, defaultValue) {
    const [state, setState] = React.useState( function () {
        const stickyValue = window.localStorage.getItem(key);
        return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    });

    return [state, function (newState) {
        if (newState) {
            window.localStorage.setItem(key, JSON.stringify(newState));
        }
        else
        {
            window.localStorage.removeItem(key)
        }

        setState(newState)
    }]
}

function useSessionState(key, defaultValue) {
    const [state, setState] = React.useState( function () {
        const stickyValue = window.sessionStorage.getItem(key);
        return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    });

    return [state, function (newState) {
        if (newState) {
            window.localStorage.setItem(key, JSON.stringify(newState));
        }
        else
        {
            window.localStorage.removeItem(key)
        }
        setState(newState)
    }]
}

export { usePersistedState }