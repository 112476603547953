import React, {useEffect, useState} from 'react';
import Request from "../../Network/Request";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import {useParams, useSearchParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {ButtonBase} from "@mui/material";
import WorkplaceList from "../Workplaces/WorkplaceList";

function Category(props) {
    let { categoryID } = useParams();
    let { categoryName } = useParams();

    const [categories, setCategories] = useState([]);
    const [backstack, setBackstack] = useState([]);
    const [currentCategory, setcurrentCategory] = useState(null);
    const [checkout, setCheckout] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(function () {
        var categoryList = new Request({
            name: "Checkout",
            path: "/api/checkout/start",
            params : {
                categoryID : categoryID
            }
        });

        categoryList.start(function (error, response) {
            if (!error) {
                setCheckout(response.checkout)

                console.log(response)
            }
        }.bind(this));
    }, []);

    return <>
        <WorkplaceList checkout={checkout} ></WorkplaceList>
    </>
}

export default Category;
