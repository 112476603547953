import React, {useEffect, useRef, useState} from 'react';
import Request from "../../Network/Request";
import {Alert, AlertTitle} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import useFileUpload from 'react-use-file-upload';

const graphx = {
    nodes: [
        { id: 1, label: "Node 1", title: "node 1 tootip text" },
        { id: 2, label: "Node 2", title: "node 2 tootip text" },
        { id: 3, label: "Node 3", title: "node 3 tootip text" },
        { id: 4, label: "Node 4", title: "node 4 tootip text" },
        { id: 5, label: "Node 5", title: "node 5 tootip text" }
    ],
    edges: [
        { from: 1, to: 2 },
        { from: 1, to: 3 },
        { from: 2, to: 4 },
        { from: 2, to: 5 }
    ]
};

const options = {
    layout: {
        hierarchical: false
    },
    edges: {
        color: "#000000"
    },
    height: "1000px"
};

const events = {
    select: function(event) {
        var { nodes, edges } = event;
    }
};

function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

function AlertView(props) {
    if (props.error) {
        return <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="error">
                <AlertTitle> {props.error.error_domain}</AlertTitle>Error:
                <strong> {
                    props.error.error_description
                }</strong>
            </Alert>
        </Stack>
    }

    return <></>
}

function Asset(props) {
    return <div>
        <ul>
            {
                JSON.stringify(props)
            }
        </ul>
    </div>
}

function DropZone(props) {
    const {
        files,
        fileNames,
        fileTypes,
        totalSize,
        totalSizeInBytes,
        handleDragDropEvent,
        clearAllFiles,
        createFormData,
        setFiles,
        removeFile,
    } = useFileUpload();

    const inputRef = useRef(null);

    const handleSubmit = async (e) => {

    };

    var [fileList , setFileList] = React.useState([])
    var [uploads , setUploads] = React.useState([])

    function createAsset(file) {
        var uploadNewRequest = new Request({
            name: "Upload File",
            path: "/api/upload/new"
        });

        uploadNewRequest.start(function (error, response) {
            if (!error)
            {
                var asset = response.asset

                file.asset = asset

                uploadFile(file)
            }
            else
            {
                console.log("Error files")
            }
        }.bind(this));
    }
    const [assets , setAssets] = React.useState([])
    function uploadFile(file) {
        console.log("Upload")
        console.log(file)

        var asset = file.asset

        var uploadRequest = new Request({
            name: "Upload File",
            path: "/upload",
            params : file.file,
            headers : {
                "x-asset-upload-token" : asset.assetToken,
                "content-type" : file.fileType
            }
        });

        uploadRequest.start(function (error, response) {
            if (!error)
            {
                var asset = response.asset

                setAssets([...assets, asset])

                console.log(response)

                props.onUploaded()

            }
            else
            {
                console.log(error)
            }
        }.bind(this));
    }

    useEffect(function () {
        var index = 0
        var fileList = []

        fileNames.forEach(function (fileName) {
            var fileName = fileNames[index]
            var file = files[index]
            var fileType = fileTypes[index]

            var file = {
                fileName : fileName,
                file : file,
                fileType : fileType
            }

            fileList.push(file)
            createAsset(file)

            removeFile(fileName)
            index++
        })


    }, [fileNames]);

    return <div css={CSS}>
        <h1>Upload Files</h1>
        <Grid md={12} container direction="row" justifyContent="flex-end" alignItems="flex-start" >
            <Grid item height={'300px'} md={1} bgcolor={'#FFFFFF'}>
            </Grid>
            <Grid  sx={{ borderRadius: 2 }} container md={10} direction="column" justifyContent="space-evenly" alignItems="stretch" padding={'20px'} bgcolor={'#FFFFFF'}>
                {
                    assets.map(function (asset){
                        return <Grid item  md={12}>
                                <Typography variant="subtitle1" >
                                    {asset.assetcdn}
                                </Typography>
                            </Grid>
                    })
                }
            </Grid>
            <Grid item height={'300px'} md={1} bgcolor={'#FFFFFF'}>
            </Grid>
        </Grid>

        <p>Please use the form to your right to upload any file(s) of your choosing.</p>
        <div className="form-container">
            {/* Display the files to be uploaded */}
            <div >
                <ul>
                    {fileNames.map((name) => (
                        <li key={name}>
                            <span>{name}</span>

                            <span onClick={() => removeFile(name)}>
                  <i className="fa fa-times" />
                </span>
                        </li>
                    ))}
                </ul>
                {
                    files.map(function (file) {
                        return <Asset file={file}></Asset>
                    })
                }
                {
                    files.length > 0 && (
                    <ul>
                        <li>File types found: {fileTypes.join(', ')}</li>
                        <li>Total Size: {totalSize}</li>
                        <li>Total Bytes: {totalSizeInBytes}</li>

                        <li className="clear-all">
                            <button onClick={() => clearAllFiles()}>Clear All</button>
                        </li>
                    </ul>)
                }
            </div>

            {/* Provide a drop zone and an alternative button inside it to upload files. */}
            <div onDragEnter={handleDragDropEvent}
                onDragOver={handleDragDropEvent}
                onDrop={(e) => {
                    handleDragDropEvent(e);
                    setFiles(e, 'a');
                }}
                bgColor={'#443333'}
                 style={{height:'200px'}}>


                <p>Drag and drop files here</p>

                <button onClick={function () {

                    inputRef.current.click()
                }}>Or select files to upload</button>

                {/* Hide the crappy looking default HTML input */}
                <input ref={inputRef}
                    type="file"
                    multiple
                    style={{ display: 'none' }}
                    onChange={(e) => {
                        setFiles(e, 'a');
                        inputRef.current.value = null;
                    }}
                />
            </div>
        </div>

        <div className="submit">
            <button onClick={handleSubmit}>Submit</button>
        </div>
    </div>
}

export default DropZone;
