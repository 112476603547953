import React, {useEffect, useState} from 'react';
import Request from "../../Network/Request";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { sha256, sha224 } from 'js-sha256';
import Typography from "@mui/material/Typography";
import {Alert} from "@mui/material";
import {usePersistedState} from "../Imports";
import SectionView from "../Layout/SectionView";
import VStack from "../Layout/VStack";
import ErrorView from "../Views/CustomViews/ErrorView";
import ScreenCenter from "../Layout/ScreenCenter";

function LoginEmail(props) {
    const [email, setEmail] = useState(null);
    const [validationCode, setValidationCode] = useState(null);
    const [credentialsID, setCredentialsID] = usePersistedState('emailCredentialsID', null);
    const [user, setUser] = usePersistedState('user', null);
    const [session, setSession] = usePersistedState('session', null);
    const [error, setError] = useState(null);

    function sendEmail() {
        var number = "bc747e2841d5" + "a2583a28149438ffa6bfcb9" + "1ed99c4d1a2a260" + "80fa2fb2eaa00680d0" + "f3e49097e78f343" + "805c28f7b3cb17579";
        var total = email + number;
        var verif = String(sha256(total))

        var options = {
            name: "Login Phone",
            path: "/authenticate/loginEmail",
            params : {
                email : email,
                verification : verif
            }
        }

        var loginRequest = new Request(options);

        loginRequest.start(function (error, response) {
            if (!error) {
                setCredentialsID(response.credentials.credentialsID)
            }
            else {
                setError(error)
            }
        }.bind(this));
    }

    function verifyCode() {
        var options = {
            name: "Verify Code",
            path: "/authenticate/loginemailcode",
            params : {
                emailCode : validationCode,
                credentialsID : credentialsID
            }
        }

        var loginCodeRequest = new Request(options);

        loginCodeRequest.start(function (error, response) {
            if (!error) {
                setUser(response.user)
                setSession(response.session)
                setCredentialsID(null)
                setTimeout(function () {
                    window.location = "/";
                },200)
            }
            else
            {
                setError(error)
            }
        }.bind(this));
    }

    function verifyCodeView() {
        if (!credentialsID) {
            return <></>
        }

        return <VStack>
            <Alert align={'left'} severity="success">Success! We've just sent you an email containing a verification code. Kindly enter the code below to continue.</Alert>
            <TextField fullWidth id="email" name='ds' label="Validation Code" variant="outlined" onChange={function (event) {
                setValidationCode(event.target.value)
            }} />
        </VStack>
    }

    function actionButton() {
        if (credentialsID) {
            return <VStack>
                <Button fullWidth variant="contained" onClick={function () {
                    verifyCode()
                }}>Verify Email Code</Button>
                <Button fullWidth variant="outlined" onClick={function () {
                    sendEmail()
                }}>Resend email</Button>
            </VStack>
        }

        return <Button fullWidth variant="contained" onClick={function () {
            sendEmail()
        }}>Send Email</Button>
    }

    return <SectionView>
        <VStack padding={'10px'}>
            <Typography align={'left'} variant={'h5'} fontWeight={'bold'}>Email Authentication</Typography>
            <Typography align={'left'}>Securely verify your identity with our reliable email authentication process. </Typography>
            <Typography align={'left'}>Protect your account and ensure that only authorized users can access your information. Enter the email address associated with your account, and we'll send a verification link to your inbox. Simply click on the link to complete the authentication process and gain seamless access to your account.</Typography>
            <TextField fullWidth id="textfield-email" label="Email" variant="outlined" onChange={function (event) {
                setEmail(event.target.value)
            }}  />
            {
                verifyCodeView()
            }
            {
                actionButton()
            }
            <ErrorView error={error}></ErrorView>
        </VStack>
    </SectionView>
}

export default LoginEmail;
