import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import {useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CheckoutProductCell from "./Products/CheckoutProductCell";
import SelectedProductCell from "./Products/SelectedProductCell";
import VStack from "../Layout/VStack";

function CheckoutProducts(props) {
    const [refresh, setRefresh] = useState("");

    var checkout = props.checkout

    return <VStack>
        <Typography variant="h5" align={'left'} component="div" gutterBottom padding={'20px'}>
            Products
        </Typography>
        {
            checkout.workerProducts.map(function (workerProduct) {
                return <CheckoutProductCell key={ workerProduct.workerProductID } variant={props.variant} selectWorkerProduct={function (workerProduct) {

                }} workerProduct={ workerProduct }></CheckoutProductCell>
            })
        }
    </VStack>
}

export default CheckoutProducts;