import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React from "react";
import HStack from "./HStack";
import {ButtonBase} from "@mui/material";
import Typography from "@mui/material/Typography";

function CenterStack(props) {
    var children = React.Children.toArray(props.children);
    var childrenCount = children.length
    var childWidth = 12 / childrenCount

    return <Grid container direction="row" justifyContent="flex-start" alignItems="stretch"  bgcolor={'#ffffff'} >
            <Grid item xs={0} sm={0} md={2} lg={2} xl={3}>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={6} bgcolor={'#ffffff'} justifyContent="flex-start" alignItems="stretch">
                {
                    children
                }
            </Grid>
            <Grid item xs={0} sm={0}  md={2} lg={2} xl={3}>
            </Grid>
        </Grid>
}

export default CenterStack;