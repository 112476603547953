import React, {useEffect, useState} from 'react';
import Request from "../../Network/Request";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {ButtonBase, CardMedia} from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {usePersistedState} from "../Imports";
import ImageView from "../ImageView";
import Ratio from "react-ratio";
import VStack from "../Layout/VStack";
import SectionView from "../Layout/SectionView";
import HStack from "../Layout/HStack";

function CategoryCell(props) {
    const [category, setCategory] = useState(props.category);
    const [selectedCategory, setSelectedCategory] = usePersistedState("selectedCategory", null);

    function categoryHref() {
        if (category.isFinal) {
            return  '/products/' + category.categoryID
        }

        return '/products/' + category.categoryID
    }

    return <SectionView padding={'5px'}>
        <ButtonBase href={categoryHref()} onClick={function (event) {
            setSelectedCategory(category)
        }}>
            <VStack>
                <VStack lg={12}>
                    <ImageView height={'100px'} image={category.image}></ImageView>
                </VStack>
                <VStack paddingLeft={'5px'} paddingRight={'5px'} spacing={0}>
                    <Typography align={'left'} fontWeight={'regular'}  variant="body2" component="div">
                        {category.name}
                    </Typography>
                    <Typography align={'left'} variant="subtitle1" component="div">
                        { category.details }
                    </Typography>
                </VStack>
            </VStack>
        </ButtonBase>
    </SectionView>
}

export default CategoryCell;
