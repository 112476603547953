import React, {useEffect, useState} from 'react';
import Request from "../../Network/Request";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import {ButtonBase, CardMedia} from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ImageView from "../ImageView";
import AppBar from "@mui/material/AppBar";
import {SuperSEO} from "react-super-seo";
import MobileStoreButton from "react-mobile-store-button";
import ReactGA from "react-ga4";

function InstallApp(props) {
    return <Paper elevation={5} sx={{ borderRadius: 2 }} >
        <SuperSEO title={"LiteApp - Instaleaza aplicatia"} description={"Booking services"} lang="en">
        </SuperSEO>
        <Grid container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start">
            <Grid item md={3}>
            </Grid>
            <Grid container md={6}>
                <Grid item md={12} paddingTop={'50px'}>
                    <img style={{ height: '50px' }} alt="complex" src="https://washplanfiles.ams3.cdn.digitaloceanspaces.com/assets/logo/LogoLiteApp_color.png" />
                </Grid>
                <Grid item md={12} bgcolor={'#ffffff'}>
                    <h3>Nu pierde oportunitatea de a-ti imbunatati experienta prin instalarea aplicatiei noastre. Apasa unul din butoanele de mai jos pentru a o descarca acum!</h3>
                </Grid>
                <Grid container md={12} padding={'50px'}>
                    <Grid item md={6}>
                        <MobileStoreButton
                            store="ios"
                            linkProps={{ title: 'iOS Store Button' }}
                            onClick={function ()  {
                                ReactGA.event({
                                    category: 'Install App iOS',
                                    action: 'install-app-ios'
                                });

                                window.open('https://apps.apple.com/ro/app/liteapp-booking-services/id1536762014', '_blank');

                                console.log("Nice...")
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <MobileStoreButton
                            store="android"
                            url={"https://play.google.com/store/apps/details?id=com.timeplanenterprise.lite"}
                            linkProps={{ title: 'PlayStore Button' }}
                            onClick={function ()  {
                                ReactGA.event({
                                    category: 'Install App iOS',
                                    action: 'install-app-ios'
                                });

                                window.open('https://play.google.com/store/apps/details?id=com.timeplanenterprise.lite', '_blank');
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item md={12} bgcolor={'#ffffff'}>
                    <h3>Scaneaza codul cu telefonul si instaleaza aplicatia.</h3>
                </Grid>
                <Grid container md={12} bgcolor={'#ffffff'}>
                    <Grid item md={4}></Grid>
                    <Grid item md={4}>
                        <div style={{width : 200, height : 200}}>
                            <ImageView image={"https://washplanfiles.ams3.cdn.digitaloceanspaces.com/assets/website/qr-code1.png"}></ImageView>
                        </div>
                    </Grid>
                    <Grid item md={4}></Grid>
                </Grid>
            </Grid>
            <Grid item md={2}>
            </Grid>

            <Grid item md={2}>
            </Grid>

        </Grid>
    </Paper>
}

export default InstallApp;
//https://link.liteapp.co/978956b2496f33dc9b39