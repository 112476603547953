/**
 * Created by andrei on 06/03/2020.
 */
import axios from 'axios';
import getUserLocale from 'get-user-locale';

class Request
{
    constructor(props)
    {
        var item = localStorage.getItem('session')
        console.log(item)
        this.currentSession = JSON.parse(window.localStorage.getItem('session'))

        console.log("Request Session: ")
        console.log(this.currentSession)

        var envs = {
            production : {
                baseURL : "https://api.moftroomservice.com"
            },
            development : {
                baseURL : "http://192.168.1.31:3002"
            },
            debugProduction : {
                baseURL : "https://api.moftroomservice.com"
            }
        }

        var environment = process.env.NODE_ENV

        //environment = "debugProduction"

        var env = envs[environment]

        console.log(env)
        this.name = props.name;
        this.baseURL = env.baseURL;
        this.path = props.path;
        this.params = props.params;
        this.headers = props.headers;
    }

    start(callback)
    {
        this.callback = callback;

        if (!this.name)
        {
            throw new Error("No name request");
        }

        var requestPath = this.baseURL;
        if (this.path)
        {
            requestPath += this.path;
        }
        else
        {
            throw new Error("No path for request " + this.name);
        }

        var requestHeaders = {};
        if (this.currentSession)
        {
            var authToken = this.currentSession.authToken;
            requestHeaders['x-auth-token'] = authToken;
            console.log("🔑 Auth Token: " + authToken);
        }

        requestHeaders['Accept-Language'] = getUserLocale()

        if (this.headers) {
            Object.keys(this.headers).forEach(function (key) {
                requestHeaders[key] = this.headers[key]
            }.bind(this))
        }

        console.log("🌎 " + this.name + " " + requestPath);
        console.log(this.params);

        axios({
            method: 'post',
            url: requestPath,
            timeout: 15000,
            data: this.params,
            headers : requestHeaders
        }).then(function (res) {
            var errorCode = res.data.error_code;
            if (errorCode)
            {
                var error = res.data;
                if (errorCode === 807001 || errorCode == 807005)
                {
                    this.currentSession = null
                    localStorage.removeItem('session')
                    localStorage.removeItem('user')
                }

                this.handleError(error);
            }
            else
            {
                this.handleSuccess(res.data);
            }
        }.bind(this)).catch(function (error) {
            this.handleError(error);
        }.bind(this))
    }

    handleError(error)
    {
        console.log("❌ Error: (below)");
        console.log(error);
        this.callback(error, null);
    }

    handleSuccess(response)
    {
        console.log("✅ Response: " + this.name + " (below)");
        console.log(response);
        this.callback(null, response);
    }
}

export default Request;