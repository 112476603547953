import React from 'react';
import Request from '../../Network/Request'
import ReservationCell from "./ReservationCell";
import moment from "moment";

class ReservationsWorker extends React.Component
{
    constructor(props)
    {
        super(props);
        this.reservations = [];
        this.computeHeight.bind(this);
    }

    componentDidMount()
    {
        this.loadReservations();
    }

    loadReservations()
    {
        var request = new Request({
            name : "Worker Reservations",
            path : "/api/worker/reservations",
            params : {
                workerID : this.props.worker.workerID,
                date : "2020-09-14T14:03:12Z"
            }
        });

        request.start(function (error, response) {
            if (!error)
            {
                this.reservations = response.reservations;
                this.forceUpdate();
            }
        }.bind(this));
    }

    render()
    {
        var self = this;
        return (<div style={{ display:'flex', flexDirection : 'column' }}>
            {
                this.reservations.map(function (reservation) {
                    var gapHeight = self.computeHeight(reservation);
                    return <React.Fragment key={reservation.reservationID}>
                        <div style={{ height: gapHeight }}></div>
                        <ReservationCell reservation={ reservation }></ReservationCell>
                    </React.Fragment>
                })
            }
        </div>);
    }

    computeHeight(reservation)
    {
        if (this.lastReservation)
        {
            var startDate = moment(this.lastReservation.endDate);
            var endDate = moment(reservation.startDate);
            var duration = moment.duration(endDate.diff(startDate));

            this.lastReservation = reservation;

            return duration.asMinutes();
        }

        this.lastReservation = reservation;
        return 0;
    }
}

export default ReservationsWorker;