import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {usePersistedState} from "../Imports";
import {ButtonBase, ListItemIcon, Menu, MenuItem, TextField, Tooltip} from "@mui/material";
import CenterStack from "../Layout/CenterStack";
import HStack from "../Layout/HStack";
import Spacer from "../Layout/Spacer";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ViewListIcon from '@mui/icons-material/ViewList';
import SettingsIcon from '@mui/icons-material/Settings';
import VStack from "../Layout/VStack";

var fontSizeIcons = 26
var moftLogo = "https://d3j182rci8qecm.cloudfront.net/moftRS/production/assets/c0b3034f63dbf5057631/94683961243203126e8e-cover.jpeg"

function TopBar(props) {
    const [loaded, setLoaded] = useState(false);
    const [session, setSession] = usePersistedState("session", {});
    const [user, setUser] = usePersistedState('user', null);

    function loginButton() {
        if (session && user && user.userRoles.includes("guest")) {
            return <Tooltip title="Login" xs={1} md={1} xl={1}>
                <Button  color='primary' onClick={function () {
                    window.location = "/login"
                }}>
                    <AccountCircleIcon sx={{ fontSize: fontSizeIcons }}></AccountCircleIcon>
                </Button>
            </Tooltip>
        }
        else
        {
            return <Tooltip title={"Profile"} xs={1} md={1} xl={1} >
                <Button color='primary' onClick={function () {
                    window.location = "/profile"
                }}><AccountCircleIcon sx={{ fontSize: fontSizeIcons }}></AccountCircleIcon></Button>
            </Tooltip>
        }
    }

    return  <CenterStack>
        <HStack padding={'10px'}>
            <ButtonBase xs={2} md={2} lg={1} xl={1} onClick={function () {
                window.location = "/"
            }} width={'100%'}>
                <img style={{ height: '40px' }} alt="complex" src={moftLogo} />
            </ButtonBase>
            <Spacer xs={6}></Spacer>
            {
                //<TextField xs={6} placeholder={'Search'} fullWidth></TextField>
            }

            <DashboardButton xs={2} md={1} ></DashboardButton>
            {
                loginButton()
            }
        </HStack>
    </CenterStack>

    return <CenterStack>
        <HStack padding={'10px'}>
            <ButtonBase xs={2} md={2} lg={1} xl={1} onClick={function () {
                window.location = "/"
            }} width={'100%'}>
                <img style={{ height: '40px' }} alt="complex" src={moftLogo} />
            </ButtonBase>
            <Spacer xs={2} md={6} lg={6} xl={7}></Spacer>
            <DashboardButton xs={2} md={1} ></DashboardButton>
            <Tooltip title="My reservations" xs={2} md={1} lg={1} xl={1} >
                <Button onClick={function () {
                    window.location = "/reservations"
                }}>
                    <ViewListIcon sx={{ fontSize: fontSizeIcons }}></ViewListIcon>
                </Button>
            </Tooltip>
            <Tooltip title="Notifications" xs={2} md={1} xl={1} xl={1}>
                <Button  onClick={function () {
                    window.location = "/notifications"
                }}>
                    <NotificationsIcon sx={{ fontSize: fontSizeIcons }}></NotificationsIcon>
                </Button>
            </Tooltip>
            {
                loginButton()
            }
        </HStack>
    </CenterStack>
}

function DashboardButton() {
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [session, setSession] = usePersistedState("session", {});
    const [user, setUser] = usePersistedState('user', null);

    const handleClose = () => {
        setOpen(false)
    };

    function openProducts() {
        window.location = '/dashboard/products'

    }

    function openAssets() {
        window.location = '/dashboard/assets'
    }

    function openVariations() {
        window.location = '/dashboard/variations'
    }

    if (session && user && user.userRoles.includes("admin")) {
        return <>
            <Menu id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                  }}
                  open={open}>
                <MenuItem onClick={handleClose}>X</MenuItem>
                <MenuItem onClick={function () {
                    window.location = '/dashboard/categories'
                }}>Categories</MenuItem>
                <MenuItem onClick={openVariations}>Variations</MenuItem>
                <MenuItem onClick={openProducts}>Products</MenuItem>
                <MenuItem onClick={openAssets}>Assets</MenuItem>
            </Menu>
            <Button onClick={function (event) {
                setAnchorEl(event.currentTarget)
                setOpen(true)
            }}>
                <SettingsIcon></SettingsIcon>
            </Button>
        </>
    }

    return <></>
}

export default TopBar;
