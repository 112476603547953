import React, {useEffect, useState} from 'react';
import Request from "../../Network/Request";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import {useParams, useSearchParams} from "react-router-dom";
import TextField from '@mui/material/TextField';
import {usePersistedState} from "../Imports";
import Grid from "@mui/material/Grid";

function LoginCode(props) {
    const [categories, setCategories] = useState([]);
    const [code, setCode] = useState([]);
    const [error, setError] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [user, setUser] = usePersistedState('user', null);
    const [session, setSession] = usePersistedState('session', null);

    let { credentialsID } = useParams();

    useEffect(function () {

    }, [error]);

    function sendSMS() {
        var options = {
            name: "Login Phone",
            path: "/authenticate/logincode",
            params : {
                credentialsID : credentialsID,
                validationCode : code
            }
        }

        var loginRequest = new Request(options);

        loginRequest.start(function (error, response) {
            if (!error) {
                setUser(response.user)
                setSession(response.session)
                setTimeout(function () {
                    window.location = "/";
                },200)
            }
        }.bind(this));
    }

    function errorx() {
        if (error) {
            return <label>{error.error_description}</label>
        }
        return  <></>
    }

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return  <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center">
        <Grid item md={4}>
        </Grid>
        <Grid item xs={12} md={4}>
            <TextField id="outlined-basic" label="Code" variant="outlined" onChange={function (e) {
                setCode(e.target.value)
                setError(null)
            }
            } />
        </Grid>
        <Grid item md={4}>
            <Button variant="contained"  onClick={function () {
                sendSMS()
            }
            }>Verify Code</Button>
        </Grid>
    </Grid>
}

export default LoginCode;
