import {Route, Routes, useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Alert} from "@mui/material";
import VStack from "../../Layout/VStack";
import Grid from "@mui/material/Grid";
import DashboardAssets from "../../Dashboard/DashboardAssets";
import DashboardCategories from "../../Dashboard/DashboardCategories";
import DashboardCategory from "../../Dashboard/DashboardCategory";
import DashboardVariations from "../../Dashboard/DashboardVariations";
import DashboardProducts from "../../Dashboard/DashboardProducts";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import HStack from "../../Layout/HStack";
import Box from "@mui/material/Box";

function CategorySelector() {
    const [categories, setCategories] = useState([]);


    useEffect(function () {





    }, [])

    return <VStack>


    </VStack>
}

function DashboardView(props) {
    const { categoryID } = useParams();

    const [payments, setpayments] = useState([]);
    const [actions, setActions] = useState([]);

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    const [error, setError] = useState(null);
    const [content, setContent] = useState(0);

    useEffect(function () {

    }, [content])

    return <HStack paddingTop={'60px'}>
        <VStack>

        </VStack>

        <Box w={2} style={{maxHeight: '80vh', overflow: 'auto'}}>
            <CategorySelector></CategorySelector>
        </Box>
        <Box w={10} style={{maxHeight: '80vh', overflow: 'auto'}}>
            <Routes>
                <Route path="/assets" element={ <DashboardAssets />}/>
                <Route path="/categories" element={ <DashboardCategories />}/>
                <Route path="/category/:categoryID" element={ <DashboardCategory />}/>
                <Route path="/variations" element={ <DashboardVariations />}/>
                <Route path="/workplaces" element={ <DashboardCategories />}/>
                <Route path="/products" element={ <DashboardProducts />}/>
                <Route path="/categories" element={ <DashboardCategories />}/>
            </Routes>
        </Box>
    </HStack>
}

export default DashboardView;

